.recipients-list {
    width: 100%;
}

.recipients-list thead tr td {
    padding-bottom: 10px;
    font-weight: bold;
}

.recipients-list tbody tr:hover {
    color: maroon;
}

.recipients-list tr .form-group {
    margin: 3px;
}

.email-view {
    display: grid;
}

.tooltip-inner {
    max-width: 400px;
}

.margin-3-left {
    margin-left:3px;
}