.redeem-reward-box {
    text-align: center;
}

.redeem-reward-box .title {
    margin: 15px 0 20px;
}

.redeem-reward-box .reward-code {
    display: block;
    margin: 15px 0;
    font-size: 1.75em;
}

.redeem-reward-box .reward-code .code-label {
    display: inline-block;
    vertical-align: middle;
}

.redeem-reward-box .reward-code .code {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}

.redeem-reward-box .countdown-timer {
    margin: 0 0 10px;
}

.redeem-reward-box .animated-image-wrapper {
    display: block;
    width: 50px;
    margin: 0 auto 15px;
}

.redeem-reward-box .animated-image-wrapper .img {
    display: block;
    max-width: 100%;
}

.redeem-reward-box .btn-row {
    margin: 15px 0;
}

.redeem-reward-box .btn-row .btn {
    min-width: 100px;
    margin: 0 10px;
}

.redeem-reward-box .animate-if-wrapper {
    position: relative;
}

.redeem-reward-box .animate-if.ng-enter, .animate-if.ng-leave {
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.redeem-reward-box .animate-if.ng-enter,
.redeem-reward-box .animate-if.ng-leave.ng-leave-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity:0;
}

.redeem-reward-box .animate-if.ng-enter {
    transform: translate3d(50px, 0, 0);
}

.redeem-reward-box .animate-if.ng-leave.ng-leave-active {
    transform: translate3d(-50px, 0, 0);
}

.redeem-reward-box .animate-if.ng-leave,
.redeem-reward-box .animate-if.ng-enter.ng-enter-active {
    position: relative;
    opacity:1;
    transform: translate3d(0, 0, 0);
}

.redeem-reward-box .animate-if.ng-leave {

}

.redeem-reward-box .animate-if.ng-enter.ng-enter-active {

}