game,
game-preview,
trivia-game {
    display: block;
    height: 100%;
    background: #F5F5F5;
}

game-preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#main-container.horizontal-rotated game-preview {
    top: 0;
}

game-preview .game-preview-pre-load {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.game-preview-iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: opacity .25s ease;
    transition: opacity .25s ease;
}

game-preview.loaded .game-preview-iframe,
game-preview.loaded-add .game-preview-iframe {
    opacity: 1;
}

.disable-portrait, .disable-landscape {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2C5878;
    background-image: url('/images/pleaserotateportrait.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    white-space: nowrap;
    text-align: center;
    z-index: 9999;
}

.disable-landscape {
    background-image: url('/images/pleaserotatelandscape.png');
}

@media (orientation: portrait) {
    .disable-portrait {
        display: block;
    }
}

@media (orientation: landscape) {
    .disable-landscape {
        display: block;
    }
}

.disable-portrait:before, .disable-landscape:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.disable-portrait .inner, .disable-landscape .inner {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    color: #FFFFFF;
    font-size: 18px;
    font-size: 5.5vw;
}

.disable-portrait .inner img, .disable-landscape .inner img {
    max-width: 80%;
}

/* Trivia Game */

.trivia-game {
    position: relative;
    width: 100%;
    min-height: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.trivia-game * {
    box-sizing: border-box;
}

.trivia-game-header {
    display: block;
    width: 100%;
    margin: 0;
    /* padding: 48px 0 0; */
    padding: 0;
}

.trivia-game .top-details-spacer {
    visibility: hidden;
    min-height: 44px;
    overflow-x: hidden;
}

.trivia-game .top-details-spacer img {
}

.trivia-game-header .top-details {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    overflow: hidden;
    padding: 6px 10px 0;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.trivia-game-header .client-logo {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;
    border: none;
    /* height: 36px; */
    max-width: 40%;
    white-space: nowrap;
}

.trivia-game-header .client-logo:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.trivia-game-header .client-logo img {
    display: inline-block;
    max-height: initial;
    max-width: initial;
    vertical-align: middle;

}

.trivia-game .hud-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.trivia-game .hud-item {
    float: left;
    margin-left: 12px;
    text-align: center;
}

.trivia-game .hud-item:first-child {
    margin-left: 0;
}

.trivia-game .hud-item .label {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    color: #ABACAA;
    text-transform: uppercase;
}

.trivia-game .hud-item .value {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #40423E;
    font-weight: 700;
}

.trivia-game .sound-toggle-btn {
    /*
    position: fixed;
    top: 5px;
    left: 10px;
    z-index: 100;*/
}

.trivia-game-header .hud-list {
    float: right;
}

.trivia-game-header .game-logo {
    margin: 0;
    position: relative;
    width: 100%;
    min-height: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.trivia-game-header .game-logo img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 360px;
}

.trivia-game-content {
    overflow: hidden;
    padding: 10px 0;
    background: #F5F5F5;
}

@media (min-width: 768px) {
    .trivia-game-content {
        padding-top: 20px;
    }
}

/*.trivia-game-content:before,
.trivia-game-content:after {
    content: "";
    display: table;
    clear: both;
}*/

.trivia-game-content .questions-qty {
    margin: 0 5px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    color: #ABACAA;
    text-transform: uppercase;
    text-align: center;
}

.trivia-game-content .questions-qty-circles {
    margin: 0;
    font-size: 16px;
    line-height: 1em;
    color: #ABACAA;
    /* text-transform: none;*/
    text-align: center;
}

.trivia-game-content .questions-qty-circles ul {
    color: #999;
    display: inline-block;
    padding: 0;
    list-style-position: inside;
    margin-bottom: 0;
}

.trivia-game-content .questions-qty-circles ul li {
    font-size: 40px;
    margin-left: 5px;
    line-height: 1em;
    max-width: 20px;
    max-height: 40px;
    overflow: hidden;
}


.trivia-game-content .question-text {
    margin: 5px 5px 15px;
    font-size: 18px;
    line-height: 24px;
    color: #23243B;
    font-weight: 700;
    text-align: left;
    padding: 0 10px;
}

@media (min-width: 768px) {
    .trivia-game-content .question-text {
        margin: 10px 20px 20px;
        font-size: 24px;
        line-height: 30px;
    }
}

.trivia-game-content .trivia-game-answers {
    /* overflow: hidden; */
    margin: 10px 0;
    padding: 0;
    list-style: none;
}

.trivia-game-content .trivia-game-answer {
    position: relative;
    margin-top: -1px;
    border: 1px dotted #B8DDD8;
    /* width: 400px; */
}

.trivia-game-content .trivia-game-answer:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    background-color: #FF6600;
}

.trivia-game-content .trivia-game-answer.orange:before {
    background-color: #FF6600;
}

.trivia-game-content .trivia-game-answer.green:before {
    background-color: #339900;
}

.trivia-game-content .trivia-game-answer.yellow:before {
    background-color: #FFCC00;
}

.trivia-game-content .trivia-game-answer.turquoise:before {
    background-color: #33CCCC;
}

.trivia-game-content .trivia-game-answer.blue:before {
    background-color: #417DCC;
}

.trivia-game-content .trivia-game-answer.purple:before {
    background-color: #CC47BE;
}

.trivia-game-content .trivia-game-answer.brown:before {
    background-color: #8B4513;
}

.trivia-game-content .trivia-game-answer.pink:before {
    background-color: #FFC0CB;
}

.trivia-game-content .col-md-6:first-child .trivia-game-answer {
    margin-top: 0;
}

.trivia-game-content .trivia-game-answer .answer-value-wrapper {
    display: block;
    margin: 0 0 0 15px;
    padding: 5px 15px;
    background: #FFFFFF;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #23243B;
    cursor: pointer;
    overflow: hidden;
}

.trivia-game-content .trivia-game-answer .answer-value input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0 !important;
    z-index: 1;
}

@media (min-width: 768px) {
    .trivia-game-content .trivia-game-answer .answer-value-wrapper {
        font-size: 16px;
        line-height: 24px;
    }
}

.trivia-game-content .trivia-game-answer .answer-value {
    display: flex;
    min-height: 60px;
    align-items: center;
}

.trivia-game-content .trivia-game-answer.match-choice .answer-value,
.trivia-game-content .trivia-game-answer.match-match .answer-value {
    display: block;
    min-height: 60px;
    white-space: nowrap;
    overflow-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.trivia-game-content .trivia-game-answer .answer-value:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.trivia-game-content .trivia-game-answer .answer-value span {
    vertical-align: middle;
    margin-left: 4px;
    outline: none;
}

.trivia-game-content .trivia-game-answer media-resource p {
    margin: 0;
}

.trivia-game-content .trivia-game-answer .answer-value-wrapper img {
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
    max-height: 46px;
}

@media (min-width: 768px) {
    .trivia-game-content .trivia-game-answer .answer-value-wrapper img {
        max-height: 70px;
    }
}

.trivia-game-content .trivia-game-answer.selected .answer-value-wrapper {
    background-color: #FFEDA6;
}

.trivia-game-content .trivia-game-answer.correct .answer-value-wrapper {
    background-color: #3EB304;
}

.trivia-game-content .trivia-game-answer.incorrect .answer-value-wrapper {
    background-color: #F3694E;
}

.trivia-game-content .submit-btn-wrapper {
    margin: 30px 0 0;
    text-align: center;
}

.trivia-game-content .submit-btn {
    min-width: 200px;
}

@media (min-width: 992px) {
    .trivia-game-content .trivia-game-answers {
        margin-top: -15px;
    }

    .trivia-game-content .trivia-game-answer {
        margin-top: 15px !important;
    }
}

.trivia-game-reward-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    font-size: 0;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.trivia-game-reward-wrapper:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.trivia-game-reward {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-align: left;
    font-size: 14px;
    max-width: 400px;
    min-width: 290px;
    padding: 19px;
    background-color: #f5f5f5;
    border: 1px solid #C5C1C1;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    z-index: 10;
}

.trivia-game-reward .hud-list-wrapper {
    text-align: center;
    margin: 0 0 10px;
    border-bottom: 1px solid #CCCCCC;
}

.trivia-game-reward .hud-list {
    display: inline-block;
}

.trivia-game-reward .reward-img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.trivia-game-reward .reward-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.trivia-game-reward .btn-row {
    text-align: center;
    margin: 10px 0 0;
}

.trivia-game-reward .btn-row .btn {
    min-width: 100px;
}

.trivia-game .start-game-block {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
}

.trivia-game .start-game-block:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.trivia-game .btn-start {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-transform: uppercase;
}

.trivia-game .row-answer-explanation {
    overflow: hidden;
}

.answer-explanation {
    position: relative;
    padding: 20px;
    margin: 15px 0 0;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
}

.answer-explanation:before,
.answer-explanation:after {
    display: table;
    content: "";
    clear: both;
}

.answer-explanation .explanation-img {
    float: left;
    margin: 0 15px 8px 0;
    max-width: 40%;
    max-height: 150px;
    border-radius: 5px;
}

@media (min-width: 768px) {
    .answer-explanation .explanation-img {
        max-width: 150px;
        max-height: 150px;
    }
}

.answer-explanation .explanation-title {
    margin: 0 0 15px;
    font-size: 36px;
}

.answer-explanation .explanation-text {
    margin: 5px 0 0;
}

.answer-explanation .row-video {
    margin-top: 15px;
}

.modal-trivia-answer {
    border-radius: 6px;
    overflow: hidden;
}

.modal-trivia-answer .modal-header {
    border-bottom: none;
}

.modal-trivia-answer.correct .modal-header {
    color: #3c763d;
    background: #dff0d8;
}

.modal-trivia-answer.wrong .modal-header {
    color: #8a6d3b;
    background: #fcf8e3;
}

.modal-trivia-answer .modal-header .btn-lg {
    margin-top: -5px;
}

.modal-trivia-answer .modal-body {
    padding: 0;
}

.modal-trivia-answer .answer-explanation {
    position: relative;
    padding: 20px;
    margin: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    border-top: 1px solid #e5e5e5;
}

.modal-trivia-answer {
    border-radius: 6px;
    overflow: hidden;
}

.modal-trivia-answer .modal-header {
    border-bottom: none;
}

.modal-trivia-answer.correct .modal-header {
    color: #3c763d;
    background: #dff0d8;
}

.modal-trivia-answer.wrong .modal-header {
    color: #8a6d3b;
    background: #fcf8e3;
}

.modal-trivia-answer .modal-header .btn-lg {
    margin-top: -5px;
}

.modal-trivia-answer .modal-body {
    padding: 0;
}

.modal-trivia-answer .answer-explanation {
    position: relative;
    padding: 20px;
    margin: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    border-top: 1px solid #e5e5e5;
}

.modal-trivia-answer .answer-info-bottom-button {
    display: none;
    padding: 20px 0;
}

    /*Match column type */

.trivia-game .match {
    margin: 0 15px;
    border-radius: 6px;
    background: #FFFFFF ;
}
.trivia-game .match-place {
    min-height: 80px;
    margin-top: 15px;
}

.trivia-game-content .trivia-game-answer.match .answer-value-wrapper {
    display: flex;
    justify-content: center;
    margin: 0;
    border-radius: 6px;
    height: 80px;
}

.trivia-game-content .trivia-game-answer.match-match .answer-value-wrapper {
    display: flex;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.trivia-game-content .trivia-game-answer.match-choice .answer-value-wrapper>span {
    padding: 0 5px 0 0;
}

.trivia-game .match .mixed {
    flex-direction: row;
}

.trivia-game .match .mixed .lobby-item-image-box {
    flex-shrink: 0;
}

.trivia-game .match .answer-value-wrapper+div {
    position: absolute;
    bottom: 3%;
    right: 1%;
    color: #287b70;
    background: white;
    padding: 0 6px 3px 6px;
    border-top-left-radius: 12px;
    font-weight: 700;
}

.trivia-game .match.correct .answer-value-wrapper+div {
    background-color: #3EB304;
}

.trivia-game .match.incorrect .answer-value-wrapper+div {
    background-color: #FF6600;
}

.trivia-game .hover-match {
    opacity: 0.6;
    background-color: lightgrey;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
}

.trivia-game-content .match.trivia-game-answer:before {
    content:none;
    width: 0;
}

#preview img {
    margin: 10px;
}

#preview span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #23243B;
}

#preview {
    border: 1px dotted #B8DDD8;
    border-radius: 6px;
    background: rgba(245, 245, 245, 0.9);
    z-index: 999999;
    position: absolute;
    text-align: center;
    overflow: hidden;
    -webkit-animation: fadein 1s;
    -moz-animation: fadein 1s;
    -ms-animation: fadein 1s;
    -o-animation: fadein 1s;
    animation: fadein 1s;
    padding: 10px;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.zoomContainer, .zoomContainer *,
#preview, #preview *,
.trivia-game-content .match {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select:none;
    user-select:none;
    -o-user-select:none;
}

.zoomContainer {
    z-index: 999999;
}

.trivia-game-content .trivia-game-answer.match .answer-value-wrapper img {
    min-height: 70px;
}

.trivia-game-content .trivia-game-answer.match .answer-value span {
    margin-left: 0;
}

.trivia-timer {
    text-align: center;
    font-size: 30px;
    color: #74BBB2;
    font-weight: 600;
    font-family: monospace;
}

@media (max-width: 414px) {
    .trivia-game-content .trivia-game-answer.match .answer-value-wrapper img {
        min-height: 60px;
    }

    .trivia-game-content .trivia-game-answer.match .answer-value-wrapper {
        overflow: hidden;
        height: 70px;
        min-height: 70px;
    }

    .trivia-game .match.match-choice {
        margin: 0 0 0 5px;
    }

    .trivia-game .match.match-match {
        margin: 0 5px 0 0;
    }

    .trivia-game .match-place {
        min-height: 75px;
        margin-top: 0;
    }

    .trivia-game .hover-match {
        margin-left: unset;
        margin-right: unset;
    }

    .trivia-game .match span,
    #preview span{
        font-size: 12px;
        line-height: 16px;
    }

    .trivia-game .match img {
        margin-right: 5px;
    }
}


    /* Trivia Game End */

/* Collect Modal */
.modal-collect-reward .reward {
    margin: 15px 0;
}

.modal-collect-reward .reward .img {
    display: block;
    margin: 10px auto;
    max-width: 100%;
    max-height: 200px;
}

.modal-collect-reward .reward figcaption {
    margin: 0 5px;
    font-size: 18px;
    text-align: center;
}

.modal-collect-reward .btn-wrapper {
    margin: 20px 0 10px;
    text-align: center;
}

.modal-collect-reward .btn-collect {
    display: block;
    width: 100%;
 
    text-transform: uppercase;
}

.pad-right5 {
    padding-right: 5px;
}

.pad-left5 {
    padding-left: 5px;
}

@media (min-width: 680px) {
    .introjs-tooltip {
        left: 35px !important;
    }
}

.no-events, .no-events * {
    pointer-events: none;
    opacity: 0.9;
}

.back-next-buttons {
    text-align: center;
}

.back-next-buttons .form-group {
    display: inline-block;
    margin-right: 15px;
}