.behavior-effect-box {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.behavior-effect {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.behavior-effect-fireworks {
    background: rgba(0,0,0,0.5);
}

.behavior-effect-fireworks .reward {
    position: absolute;
    width: 280px;
    height: 280px;
    max-height: 70%;
    max-width: 90%;
    left: 50%;
    top: 50%;
    margin: -140px 0 0 -140px;
}

.behavior-effect-fireworks .reward .reward-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border: 2px solid #e8bd42;
    border-radius: 8px;
}

.behavior-effect-fireworks .close-btn {
    position: absolute;
    /*left: 50%;*/
    bottom: 10px;
    width: 160px;
    margin: 0 auto;
    left: 0;
    right: 0;
    cursor: pointer;
}

.behavior-effect .game-score {
    text-align: center;
    color: #000;
}

@media screen and (min-width: 768px) {
    .behavior-effect-fireworks .close-btn {
        /*left: 50px;
        margin-left: 0;*/
        bottom: 50px;
    }
}

@media screen and (min-width: 768px) {
    .dashboard-page {
        width: 50%;
        margin: 10px auto;
    }
}
/********** Leaderboard *************/
/*
.list-leaders {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-leaders > li {
    margin: 12px 12px 0 0;
}

.list-leaders > li:first-child {
    margin-top: 0;
}

.list-leaders .leader-item {
    display: table;
    width: 100%;
}

.list-leaders .place,
.list-leaders .value,
.list-leaders .img-wrapper,
.list-leaders .name {
    display: table-cell;
    vertical-align: middle;
}

.list-leaders .place,
.list-leaders .value {
    width: 1%;
    min-width: 25px;
    white-space: nowrap;
    font-size: 1.43em;
    text-align: right;
}

.list-leaders .img-wrapper {
    padding-left: 3.7%;
    width: 18.5%;
}

@media (min-width: 992px) {
    .list-leaders .img-wrapper {
        padding-left: 10px;
        width: 62px;
    }
}

.list-leaders .img-wrapper img {
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
}

.list-leaders .name {
    padding: 0 0 0 20px;
    font-size: 1.28em;
    font-weight: 400;
}

.list-leaders .name.no-avatar {
    padding-left: 80px;
}

.list-leaders .value {
    padding: 0 0 0 10px;
    }
*/

/****** Fireworks Effect  ******/

.behavior-effect-fireworks .pyro {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.behavior-effect-fireworks .pyro > .before, .pyro > .after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
    -webkit-animation: 1s pyro-bang ease-out infinite backwards, 1s pyro-gravity ease-in infinite backwards, 5s pyro-position linear infinite backwards;
    animation: 1s pyro-bang ease-out infinite backwards, 1s pyro-gravity ease-in infinite backwards, 5s pyro-position linear infinite backwards;
}

.behavior-effect-fireworks .pyro > .after {
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes pyro-bang {
    to {
        box-shadow: 24px -7.66667px #00ffb3, -5px -59.66667px #1aff00, 150px -84.66667px #7700ff, 201px -23.66667px #2b00ff, -58px -66.66667px #a6ff00, 46px -274.66667px #ff003c, 230px -86.66667px #00ffd9, -127px -257.66667px #ff0033, 233px 51.33333px #ff4d00, 190px -8.66667px #00ff3c, 243px -409.66667px #f7ff00, -148px 63.33333px #ff00aa, -72px 21.33333px #aaff00, -82px -53.66667px #99ff00, -199px -124.66667px #f200ff, -39px 57.33333px #ffb700, -105px -192.66667px #00ff1e, 165px -184.66667px #00ff8c, 204px -257.66667px #00ff8c, 145px -137.66667px #88ff00, 75px -205.66667px #0022ff, 146px -305.66667px #00ff48, -233px -225.66667px #00ff6a, -55px -163.66667px #ff0022, -156px -375.66667px #ff0040, 26px -142.66667px #00ff40, -11px 19.33333px #00fffb, 46px -281.66667px #1500ff, 86px 34.33333px cyan, -205px -164.66667px #6600ff, 214px -386.66667px #ffe100, -52px 43.33333px #fbff00, -96px -300.66667px #44ff00, 249px -33.66667px #ff0099, 94px -220.66667px #00ff6f, -151px -222.66667px #1100ff, 90px -370.66667px #ff5900, -234px 72.33333px #00ff5e, -52px -258.66667px #ff00d0, -196px 81.33333px #00ff0d, -125px -404.66667px #ee00ff, -116px -394.66667px #6fff00, 240px 10.33333px #6f00ff, -151px -358.66667px #ff1e00, -243px -248.66667px #fff700, -37px -26.66667px #ff8400, -133px -396.66667px #00f2ff, 166px 60.33333px #6200ff, 196px -64.66667px #ff6f00, -46px -345.66667px #e100ff, 178px -144.66667px #4400ff;
    }
}

@keyframes pyro-bang {
    to {
        box-shadow: 24px -7.66667px #00ffb3, -5px -59.66667px #1aff00, 150px -84.66667px #7700ff, 201px -23.66667px #2b00ff, -58px -66.66667px #a6ff00, 46px -274.66667px #ff003c, 230px -86.66667px #00ffd9, -127px -257.66667px #ff0033, 233px 51.33333px #ff4d00, 190px -8.66667px #00ff3c, 243px -409.66667px #f7ff00, -148px 63.33333px #ff00aa, -72px 21.33333px #aaff00, -82px -53.66667px #99ff00, -199px -124.66667px #f200ff, -39px 57.33333px #ffb700, -105px -192.66667px #00ff1e, 165px -184.66667px #00ff8c, 204px -257.66667px #00ff8c, 145px -137.66667px #88ff00, 75px -205.66667px #0022ff, 146px -305.66667px #00ff48, -233px -225.66667px #00ff6a, -55px -163.66667px #ff0022, -156px -375.66667px #ff0040, 26px -142.66667px #00ff40, -11px 19.33333px #00fffb, 46px -281.66667px #1500ff, 86px 34.33333px cyan, -205px -164.66667px #6600ff, 214px -386.66667px #ffe100, -52px 43.33333px #fbff00, -96px -300.66667px #44ff00, 249px -33.66667px #ff0099, 94px -220.66667px #00ff6f, -151px -222.66667px #1100ff, 90px -370.66667px #ff5900, -234px 72.33333px #00ff5e, -52px -258.66667px #ff00d0, -196px 81.33333px #00ff0d, -125px -404.66667px #ee00ff, -116px -394.66667px #6fff00, 240px 10.33333px #6f00ff, -151px -358.66667px #ff1e00, -243px -248.66667px #fff700, -37px -26.66667px #ff8400, -133px -396.66667px #00f2ff, 166px 60.33333px #6200ff, 196px -64.66667px #ff6f00, -46px -345.66667px #e100ff, 178px -144.66667px #4400ff;
    }
}

@-webkit-keyframes pyro-gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@keyframes pyro-gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}

@-webkit-keyframes pyro-position {
    0%, 19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%, 39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%, 59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%, 79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%, 99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}


@keyframes pyro-position {
    0%, 19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%, 39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%, 59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%, 79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%, 99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}


@-webkit-keyframes bills-fall {
    0%{
        top:-20px;
        -webkit-transform: rotateX(-20deg);
        transform: rotateX(-20deg);
    }

    3%{
        margin-left: -2%;
        -webkit-transform: rotateX(-80deg);
        transform: rotateX(-80deg);
    }

    7% {
        margin-left:1%;
        -webkit-transform: rotateX(-170deg);
        transform: rotateX(-170deg);
    }
    10% {
        margin-left: 2%;
        -webkit-transform: rotateX(-230deg);
        transform: rotateX(-230deg);
    }
    16% {
        margin-left: 5%;
        -webkit-transform: rotateX(-250deg);
        transform: rotateX(-250deg);
    }
    22% {
        margin-left: 3%;
        -webkit-transform: rotateX(-280deg);
        transform: rotateX(-280deg);
    }

    28% {
        margin-left: 0%;
        -webkit-transform: rotateX(-300deg);
        transform: rotateX(-300deg);
    }

    35% {
        margin-left: 3.5%;
        -webkit-transform: rotateX(-310deg);
        transform: rotateX(-310deg);
    }

    48% {
        margin-left: 1%;
        -webkit-transform: rotateX(-350deg);
        transform: rotateX(-350deg);
    }

    58% {
        margin-left: 3.5%;
        -webkit-transform: rotateX(-310deg);
        transform: rotateX(-310deg);
    }

    70%{
        margin-left: 0.5%;
        -webkit-transform: rotateX(-280deg);
        transform: rotateX(-280deg);
    }

    83% {
        margin-left: -2%;
        -webkit-transform: rotateX(-230deg);
        transform: rotateX(-230deg);

    }

    95% {
        margin-left: 2%;
        -webkit-transform: rotateX(-200deg);
        transform: rotateX(-200deg);
    }

    100% {
        margin-left: 3%;
        top: 100%; }
}


span.dollar {
    padding: 5px;
    display: block;
    position: absolute;
    z-index: 100;
    -webkit-animation: bills-fall 30s ease-out;
    animation: bills-fall 30s ease-out;
}

#coinRain,
#confettiRain{
    display:block;
    pointer-events:none;
    position: fixed;
    top:0;
    left:0;
}
