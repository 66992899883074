.redeem-box {
    margin: 20px 0;
}

.redeem-box .code-wrapper {
    text-align: center;
    margin: 20px 0;
}

.redeem-box .text-code {
    font-size: 20px;
    font-weight: 700;
}

.plain-code {
    margin-top: 5px;
    font-size: 16pt;
}

.redeem-box .btn-row {
    margin: 20px 0;
    text-align: center;
}

.reward-row .btn-row {
    margin: 15px 0 0;
}

.reward-row .btn-row .btn {
    min-width: 90px;
    margin: 0 5px 10px;
}

redeem h2 {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}

redeem .reward-row {
    margin: 30px;
}

redeem .answers-table {
    width: auto;
    max-width: 100%;
    margin: 0 auto 5px;
}

redeem .answers-table .question-label {
    display: inline-block;
    font-weight: 600;
}

redeem .answers-table .question-label:after {
    content: ":";
}

redeem .answers-table tr > td {
    padding: 3px;
    border: none;
}

receipt {
    display: block;
    opacity: 0;
}

receipt.visible {
    opacity: 1;
}

receipt > div {
    /*width: 1.6in;
    height: 3.8in;*/
    position: relative;
    /*text-align: center;*/
    margin-left: 20px;
}

receipt .reward-img {
    display: block;
    position: relative;
    max-width: 100%;
    max-height: 25%;
    margin: 0 auto;
}

receipt .reward-name {
    display: block;
    margin: 0 0 5px;
}

receipt .description-block {
    position: relative;
    /*height: 30%;
    overflow: hidden;*/
}

receipt .description-block p {
    margin: 0 0 5px;
}

receipt .pos-code {
    position: relative;
    background: #ffffff;
}

receipt .pos-code .text-code {
    font-size: 14px;
    line-height: 14px;
}

receipt .pos-code .reward-expiration {
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
}

receipt .pos-code canvas {
    width: 100px;
    height: 100px;
}

receipt.Paper3X2 {
    width: 2.8in;
    height: 1.8in;
    position: relative;
    text-align: center;
    margin: 0 auto;
}

receipt.Paper6X4 {
    width: 5.8in;
    height: 3.8in;
    position: relative;
    text-align: center;
    margin: 0 auto;
}

receipt.Paper8_5X11 {
   width: 7.2in;
   height: 10.0in;
   position: relative;
   text-align: center;
   margin: 0 auto
}


@media (max-width: 768px) {

    .thank-you .panel-body {
        padding: 0;
    }

    .thank-you div.col-xs-12 {
        padding: 5px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {

}

/*NEW Thank-You Page*/

.thank-you-page {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.thank-you-page .btn {
    display: inline-block;
    padding: 8px 12px;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: none;
    border-radius: 0;
    text-transform: uppercase;
}

.thank-you-page .btn.btn-default {
    color: #ffffff;
    background-color: #A0A0A0;
}

.thank-you-page .btn.btn-primary {
    color: #ffffff;
    background-color: #3B8BC8;
}

.thank-you-page .btn.focus,
.thank-you-page .btn:focus,
.thank-you-page .btn:hover {
    text-decoration: none;
}

.thank-you-page .btn-default.active,
.thank-you-page .btn-default.focus,
.thank-you-page .btn-default:active,
.thank-you-page .btn-default:focus,
.thank-you-page .btn-default:hover {
    background-color: #8C8C8C;
}

.thank-you-page .btn-primary.active,
.thank-you-page .btn-primary.focus,
.thank-you-page .btn-primary:active,
.thank-you-page .btn-primary:focus,
.thank-you-page .btn-primary:hover {
    background-color: #3175A5;
}

.thank-you-page .btn-disabled {
    opacity: .50;
}

.thank-you-page > .page-inner {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow: auto;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.thank-you-page .page-content {
    height: 100%;
}

.thank-you-page .pre-reward-block,
.thank-you-page .post-reward-block {
     min-height: 50px;
}

.thank-you-page .pre-reward-block {
    position: relative;
}

.thank-you-page .interaction-block {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.thank-you-page .pre-reward-block .custom-html-inner,
.thank-you-page .post-reward-block .custom-html-inner {
    width: 100%;
}

.thank-you-page .header {
    display: block;
    background-repeat: repeat;
    background-size: contain;
    /*background-image: url("../images/thank-you-header-bg.png");*/
}

.thank-you-page .header-inner {
    position: relative;
    height: 110px;
}

.thank-you-page .header .img-header {
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 90%;
    max-height: 90%;
}

.thank-you-page .footer {
    background-repeat: repeat;
    background: -webkit-linear-gradient(#0F1C25, #0F1C25);
    background: -ms-linear-gradient(#0F1C25, #0F1C25);
    background: linear-gradient(#0F1C25, #0F1C25);
}

.thank-you-page .ac-footer {
    background: none;
    text-align: center;
}
    
.thank-you-page .footer .footer-inner {
    overflow: hidden;
    padding: 15px;
    color: #7C858C;
}

.thank-you-page .footer .footer-inner .img-footer {
    margin: auto;
    max-width: 90%;
    max-height: 90%;
}

.thank-you-page .reward-main {
    background: rgba(30, 40, 44, 0.8);
	color: #B6CBD0;
}

.thank-you-page .reward-wrapper {
    overflow: hidden;
}

.thank-you-page .reward {
    padding: 40px;
}

.thank-you-page .reward .reward-title {
    margin: 0 0 20px;
    font-size: 50px;
    color: #ffffff;
}

.thank-you-page .reward .reward-description {
    font-size: 14px;
    color: #B6CBD0;
}

.thank-you-page .reward-info {
    position: relative;
    padding: 40px 20px;
}

.thank-you-page .reward-info:after {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    border: 1px solid #656E73;
}

.thank-you-page .header .header-inner,
.thank-you-page .reward-main .reward-wrapper,
.thank-you-page .footer .footer-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.thank-you-page .page-content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.thank-you-page .page-content .header,
.thank-you-page .page-content .reward-main,
.thank-you-page .page-content .footer {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.thank-you-page .page-content .pre-reward-block,
.thank-you-page .page-content .post-reward-block {
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

@media (min-width: 768px) {

    .thank-you-page .page-content .pre-reward-block,
    .thank-you-page .page-content .post-reward-block {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
    }

    .thank-you-page .pre-reward-block .custom-html-inner,
    .thank-you-page .post-reward-block .custom-html-inner {
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }

    .thank-you-page .reward-wrapper {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
    }

    .thank-you-page .reward {
        width: 70%;
    }

    .thank-you-page .reward-info {
        width: 30%;
    }

    .thank-you-page .reward-info:after {
        top: 20px;
        left: 0;
        bottom: 20px;
        right: auto;
    }
}

.thank-you-page .reward-img-wrapper {
    position: relative;
    margin: 0 40px;
    max-height: 300px;
    text-align: center;
}

.thank-you-page .reward-img-wrapper .img {
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 350ms, transform 350ms;
    -moz-transition: opacity 350ms, transform 350ms;
    -o-transition: opacity 350ms, transform 350ms;
    transition: opacity 350ms, transform 350ms;
}

.thank-you-page .reward-img-wrapper .poscode {
    position: absolute;
    padding: 6%;
    max-width: 100%;
    background: #fff;
    opacity: 0;
    -webkit-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -ms-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: opacity 350ms, transform 350ms;
    -moz-transition: opacity 350ms, transform 350ms;
    -o-transition: opacity 350ms, transform 350ms;
    transition: opacity 350ms, transform 350ms;
}

.thank-you-page .reward-img-wrapper.poscode-loaded .img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    -webkit-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -ms-transform: scale(0.2);
    transform: scale(0.2);
}

.thank-you-page .reward-img-wrapper.poscode-loaded .poscode {
    position: relative;
    display: inline-block;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.thank-you-page .reward-info-item {
    margin: 14px 0;
    text-align: center;
    font-size: 20px;
    line-height: 1.22em;
}

.thank-you-page .reward-info-item .item-label {
    display: block;
    font-weight: 400;
    color: #B6CBD0;
}

.thank-you-page .reward-info-item .item-value {
    display: block;
    font-weight: 400;
    color: #ffffff;
}

.thank-you-page .answers-table {
    font-size: 14px;
}

.thank-you-page .reward-info .btn-row {
    margin: 14px 0;
}

.thank-you-page .reward-info .btn {
    display: block;
    width: 100%;
    margin: 15px 0;
}

.thank-you-page .reward-info .btn:first-child {
    margin-top: 0;
}

.thank-you-page .reward-info,
redeem .redirect-tip {
	text-align: center;
}

.thank-you-page .redirect-link-row button{
    word-break: break-all;
}

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important;
  }
}
