.choose-reward-box {
    margin: 30px;
}

.choose-reward-box .panel-body, .choose-reward-box .panel-body .col-md-12 {
    padding: 0;
}

.selectable-rewards-list .reward-item .reward-back img {
    margin-right: 10px;
    float: left;
    max-height: 50px;
    line-height: 1em;
}

.selectable-rewards-list .reward-item .reward-back img.back-icon {
    width: 25px;
    height: 25px;
    margin: 0;
}

.choose-reward-detail {
    background: #FAFAFA;
}

.modal-header.choose-reward-detail {
    border-radius: 5px 5px 0 0;
}

.choose-reward-detail h2 {
    margin-bottom: 0;
    margin-top: 15px;
}

.choose-reward-detail img {
    max-width: 100px;
    margin-right: 15px;
}

.selectable-reward-detail p {
    margin-top: 0;
    font-weight: bold;
}

.reward-item {
    position: relative;

    -webkit-perspective: 800;
    -moz-perspective: 800;
    -ms-perspective: 800;
    perspective: 800;

    -ms-transform: perspective(800px);
    -moz-transform: perspective(800px);

    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;

    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
}

.reward-item,
.reward-front,
.reward-back {
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.reward-front,
.reward-back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;

    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
}

.reward-front {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #FFF;

    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.reward-back {
    padding: 10px;
    background: #FFF;
    border-radius: 10px;
    border: 1px solid #FFF;

    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.reward-item.hover .reward-front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.reward-item.hover .reward-back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.reward-front .glyphicon-info-sign {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 20px;
}

.rewards-list .more-info {
    position: absolute;
    top: 10px;
    right: 10px;
}

.reward-back .glyphicon-refresh {
    position: absolute;
    right: 0;
    top: 0;
}

.reward-back .details {
    font-size: 11px;
    left: 16px;
    bottom: 12px;
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

.reward-back p {
    font-size: 11px;
	line-height: 1.3em;
    padding: 0 6px;
    max-height: 10.4em;
    overflow: hidden;
}

.reward-back .reward-expiration {
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;
}

.slick-current .reward-item.hover {
    padding: 0;

    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
}

.slick-current .reward-back,
.slick-current .reward-front {
    background: #FFC;
    border: 1px solid #FFC;
}

.slick-current .reward-back .glyphicon-refresh {
    position: absolute;
    right: 10px;
    top: 10px;
}

/*.slick-current .reward-back .details {
  bottom: 0;
}*/

.mobile-reward-front, .mobile-reward-back {
    position: relative;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 400px;
}

.mobile-reward-back {
    position: absolute;
}

.mobile-reward-back .action-bar {
    padding: 10px 15px;
    background: #FAFAFA;
    border-bottom: 1px solid #EEE;
}

.mobile-reward-back .list-view-text > div {
    display: table-cell;
    vertical-align: middle;
}

.mobile-reward-back .description, .mobile-reward-back .terms {
    padding: 5px 20px;
    text-align: left;
}

.choose-reward-container.new-layout {
    background-size: cover;
    min-height: 100%;
}

.choose-reward-container.new-layout .choose-reward-box {
    margin: 0;
}

.choose-reward-header, .choose-reward-confirmation {
    text-align: center;
}

.choose-reward-confirmation {
    padding-bottom: 20px;
}
.choose-reward-header {
    padding-top: 20px;
}

.choose-reward-header img {
    height: 100px;
}

.reward-item choose-reward-list-item .reward-front,
.reward-item choose-reward-list-item .reward-back {
    display: flex;
    flex-direction: column;
}

.choose-reward-container.new-layout .reward-list.tile .reward-item {
    width: 50%;
    margin: auto;
    display: inline-flex;
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-item-image {
    height: 50%;
    top: 0;
    left: 0;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.choose-reward-container.new-layout .reward-back .reward-expiration {
    position: relative;
    bottom: unset;
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-item-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    margin-top: 15px;
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-back,
.choose-reward-container.new-layout .reward-list .reward-item .reward-front {
    border-radius: 10px;
}

.choose-reward-container.new-layout .reward-list.tile {
    margin: 0 33%;
}

.choose-reward-container.new-layout .reward-list.carousel {
    margin: 0 15%;
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-item-image div {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-back {
    background: rgba(235, 235, 235, 0.8);
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-front {
    background: rgba(255, 255, 255, 0.8);
}

.choose-reward-container.new-layout .choose-reward-theme.dark .reward-list .reward-item .reward-front,
.choose-reward-container.new-layout .choose-reward-theme.dark .reward-list .reward-item .reward-back {
    background: rgba(1,1,1,.5);
    border-width: 2px;
}

.choose-reward-container.new-layout .choose-reward-theme.dark .reward-list .reward-item .reward-front *,
.choose-reward-container.new-layout .choose-reward-theme.dark .reward-list .reward-item .reward-back * {
    color: white;
}

.choose-reward-container.new-layout .choose-reward-theme.dark .reward-list .reward-item .reward-back img.back-icon {
    filter: invert(100%);
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-front,
.choose-reward-container.new-layout .reward-list .reward-item .reward-back {
    border-width: 4px;
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-front {
    border: solid #FFF;
}

.choose-reward-container.new-layout .reward-list .reward-item .reward-back {
    border: solid rgba(211, 211, 211, 0.5);
}

.choose-reward-container.new-layout .reward-list .reward-item.slick-current .reward-front,
.choose-reward-container.new-layout .reward-list .reward-item.slick-current .reward-back {
    border: solid #edbd1c;
}

.choose-reward-container.new-layout .reward-item choose-reward-list-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.choose-reward-container.new-layout .reward-back {
    position: absolute;
    top: 0;
    left: 0;
}

.choose-reward-container.new-layout .reward-back .back-icon {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 20px;
    height: 20px;
    margin: 0;
}

.choose-reward-container.new-layout .choose-reward-theme.dark {
    background: rgba(1,1,1,0.5);
    margin: 20px 0;
    padding: 20px 0;
}

.modal.dark .modal-content {
    background: rgba(1,1,1,0.5);
}

.modal.dark .modal-content>* {
    background: rgba(1,1,1,0.5);
    color: white;
}

.choose-reward-container.new-layout .slick-prev:before,
.choose-reward-container.new-layout .slick-next:before {
    display: none;
}
.choose-reward-container.new-layout .slick-prev,
.choose-reward-container.new-layout .slick-next {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 30px;
}

.choose-reward-container.new-layout .slick-next {
    background-image: url(../images/rightarrow.png);
    margin-right: -10px;
}

.choose-reward-container.new-layout .slick-prev {
    background-image: url(../images/leftarrow.png);
    margin-left: -10px;
}

.choose-reward-container.new-layout .reward-item:focus,
.choose-reward-container.new-layout .reward-item {
    outline: none;
}

.choose-reward-container.new-layout .reward-item.slick-current {
    transform: scale(1.01);
}

.choose-reward-container.new-layout slick {
    visibility: hidden;
}

.choose-reward-container.new-layout slick.slick-initialized {
    visibility: hidden;
    animation: 1s choseRewardFadeIn;
    animation-fill-mode: forwards;
}

.choose-reward-container.new-layout .choose-reward-header {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@keyframes choseRewardFadeIn {
    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@media (max-width: 1600px) {
    .choose-reward-container.new-layout .reward-list.tile {
        margin: 0 25%;
    }
}

@media (max-width: 1440px) {
    .choose-reward-container.new-layout .reward-list.tile {
        margin: 0 20%;
    }
}

@media (max-width: 1024px) {
    .choose-reward-container.new-layout .reward-list.tile {
        margin: 0 15%;
    }

    .choose-reward-container.new-layout .reward-list.carousel {
        margin: 0 10%;
    }
}

@media (max-width: 768px) {
    .choose-reward-container.new-layout .reward-list.tile {
        margin: 0 10%;
    }

    .choose-reward-container.new-layout .reward-list.tile .reward-back .details {
        left: unset;
        right: 15px;
    }

    .choose-reward-container.new-layout .reward-list.tile .reward-item {
        width: 100%;
    }

    .choose-reward-container.new-layout .reward-list.tile .reward-item .reward-item-image {
        height: 100%;
        width: 30%;
        float: left;
        padding: 10px;
    }
    .choose-reward-container.new-layout .reward-list.tile .reward-item .reward-item-text {
        height: 100%;
        width: 70%;
        float: right;
        margin-top: 0;
    }

    .reward-list.tile .reward-item choose-reward-list-item .reward-front,
    .reward-list.tile .reward-item choose-reward-list-item .reward-back {
        flex-direction: row;
        padding: 0;
        border-width: 2px;
        min-height: 150px;
    }

    .choose-reward-container.new-layout .reward-list.tile .reward-item {
        min-height: 150px;
    }

    .choose-reward-container.new-layout .reward-list.tile .reward-item .reward-item-text {
        justify-content: center;
        text-align: left;
    }

    .choose-reward-container.new-layout .reward-list.carousel {
        margin: 0 7%;
    }

    .choose-reward-container.new-layout .reward-item.slick-current {
        transform: scale(0.9);
    }
}

@media (max-width: 425px) {
    .choose-reward-container.new-layout .reward-list.tile {
        margin: 0 2%;
    }

    .choose-reward-container.new-layout .reward-list.tile .reward-item choose-reward-list-item .reward-front,
    .choose-reward-container.new-layout .reward-list.tile .reward-item choose-reward-list-item .reward-back {
        min-height: 100px;
    }

    .choose-reward-container.new-layout .reward-list.tile .choose-reward-container.new-layout .reward-item {
        min-height: 100px;
    }

    .choose-reward-container.new-layout .slick-next {
        margin-right: 15px;
        z-index: 999;
    }

    .choose-reward-container.new-layout .slick-prev {
        margin-left: 15px;
        z-index: 999;
    }
}

@media screen and (max-width: 768px) {
    .choose-reward-box {
        margin-left: 10px;
        margin-right: 10px;
    }
}