@font-face {
	font-family: 'fontello';
	src: url('../fonts/fontello.eot');
	src: url('../fonts/fontello.eot#iefix') format('embedded-opentype'),
	  url('../fonts/fontello.woff') format('woff'),
	  url('../fonts/fontello.ttf') format('truetype'),
	  url('../fonts/fontello.svg#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
	  font-family: 'fontello';
	  src: url('../font/fontello.svg?80928161#fontello') format('svg');
	}
  }
  */
  
  [class^='icon-spin']:before,
  [class*=' icon-spin']:before {
	font-family: 'fontello';
	font-style: normal;
	font-weight: normal;
	speak: none;
  
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	/* opacity: .8; */
  
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
  
	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
  
	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: 0.2em;
  
	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */
  
	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
  
  .icon-spin3:before {
	content: '\e800';
  }
  .icon-spin5:before {
	content: '\e802';
  }
  
  /*
	 Animation for spinners
  */
  .animate-spin {
	-moz-animation: spin 1.2s infinite linear;
	-o-animation: spin 1.2s infinite linear;
	-webkit-animation: spin 1.2s infinite linear;
	animation: spin 1.2s infinite linear;
	display: inline-block;
  }
  @-moz-keyframes spin {
	0% {
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -moz-transform: rotate(359deg);
	  -o-transform: rotate(359deg);
	  -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
	}
  }
  @-webkit-keyframes spin {
	0% {
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -moz-transform: rotate(359deg);
	  -o-transform: rotate(359deg);
	  -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
	}
  }
  @-o-keyframes spin {
	0% {
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -moz-transform: rotate(359deg);
	  -o-transform: rotate(359deg);
	  -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
	}
  }
  @-ms-keyframes spin {
	0% {
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -moz-transform: rotate(359deg);
	  -o-transform: rotate(359deg);
	  -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
	}
  }
  @keyframes spin {
	0% {
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	100% {
	  -moz-transform: rotate(359deg);
	  -o-transform: rotate(359deg);
	  -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
	}
  }
  
  /*Fancy Button*/
  .btn-fancy {
	position: relative;
  }
  
  .btn-fancy .btn-fancy-text {
	display: block;
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: opacity 350ms, transform 350ms;
	-moz-transition: opacity 350ms, transform 350ms;
	-o-transition: opacity 350ms, transform 350ms;
	transition: opacity 350ms, transform 350ms;
  }
  
  .btn-fancy .btn-fancy-spinner {
	position: absolute;
	/*width: 20px;
	  height: 20px;
	  top: 0;
	  bottom: 0;*/
	left: 0;
	right: 0;
	margin: auto;
	opacity: 0;
	-webkit-transform: scale(0.2);
	-moz-transform: scale(0.2);
	-ms-transform: scale(0.2);
	transform: scale(0.2);
	-webkit-transition: opacity 350ms, transform 350ms;
	-moz-transition: opacity 350ms, transform 350ms;
	-o-transition: opacity 350ms, transform 350ms;
	transition: opacity 350ms, transform 350ms;
  }
  
  .btn-fancy.loading .btn-fancy-spinner {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
  }
  
  .btn-fancy.loading .btn-fancy-text {
	opacity: 0;
	-webkit-transform: scale(2.2);
	-moz-transform: scale(2.2);
	-ms-transform: scale(2.2);
	transform: scale(2.2);
  }
  
  /*Fancy Spinner*/
  .fancy-spinner-box {
	position: relative;
	display: block;
	min-width: 26px;
	min-height: 26px;
  }
  
  .fancy-spinner-box .fancy-spinner-content {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: opacity 350ms, transform 350ms;
	-moz-transition: opacity 350ms, transform 350ms;
	-o-transition: opacity 350ms, transform 350ms;
	transition: opacity 350ms, transform 350ms;
  }
  
  .fancy-spinner-box .fancy-spinner {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	opacity: 0;
	-webkit-transform: scale(0.2);
	-moz-transform: scale(0.2);
	-ms-transform: scale(0.2);
	transform: scale(0.2);
	-webkit-transition: opacity 350ms, transform 350ms;
	-moz-transition: opacity 350ms, transform 350ms;
	-o-transition: opacity 350ms, transform 350ms;
	transition: opacity 350ms, transform 350ms;
  }
  
  .fancy-spinner-box.loading .fancy-spinner {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
  }
  
  .fancy-spinner-box.loading .fancy-spinner-content {
	opacity: 0;
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
  }
  
  .page-loader .spinner {
	width: 80px;
	height: 30px;
	text-align: center;
	font-size: 10px;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
  }
  
  .page-loader .spinner > div {
	background-color: #9d9d9d;
	height: 100%;
	width: 6px;
	display: inline-block;
	-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
	animation: stretchdelay 1.2s infinite ease-in-out;
	margin-left: 3px;
  }
  
  .page-loader .spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
  }
  
  .page-loader .spinner .rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
  }
  
  .page-loader .spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
  }
  
  .page-loader .spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
  }
  
  @-webkit-keyframes stretchdelay {
	0%,
	40%,
	100% {
	  -webkit-transform: scaleY(0.4);
	}
  
	20% {
	  -webkit-transform: scaleY(1);
	}
  }
  
  @keyframes stretchdelay {
	0%,
	40%,
	100% {
	  transform: scaleY(0.4);
	  -webkit-transform: scaleY(0.4);
	}
  
	20% {
	  transform: scaleY(1);
	  -webkit-transform: scaleY(1);
	}
  }
  .cherry-spinner {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
  }
  
  .fancy-spinner-box.loading.wo-grow .fancy-spinner-content {
	opacity: 0;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
  }
  
  .fancy-spinner-box.loading.grow .fancy-spinner-content {
	opacity: 0;
	-webkit-transform: scale(3);
	-moz-transform: scale(3);
	-ms-transform: scale(3);
	transform: scale(3);
  }
  
  .page-loader {
	opacity: 0;
	transition: .2s ease-in-out;
  }
  
  .page-loader--visible {
	opacity: 1;
  }
  
  .page-loader--infinite {
	display: block !important;
	background: white !important;
  }
  
  /* solaire loader */
  
  .ofc-loader-solaire {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: #4f1325;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content {
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 600px;
	width: 100%;
	padding:  0 15px;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__img {
	width: 160px;
	max-width: 100%;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__preloader {
	position: relative;
	height: 37px;
	width: 100%;
	background: linear-gradient(to right, #ac1e26, #f8a02c);
	border-radius: 10px;
	margin: 0 0 15px;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__preloader:before {
	content: '';
	position: absolute;
	display: block;
	left: 2px;
	top: 2px;
	width: calc(100% - 4px);
	height: calc(100% - 4px);
	background: #4f1325;
	border-radius: 8px;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__preloader:after {
	content: '';
	position: absolute;
	display: block;
	height: calc(100% - 10px);
	width: calc(50% - 4px);
	left: 4px;
	top: 5px;
	transform: translate3d(0, 0, 0);
	background: linear-gradient(to right, #ac1e26, #f8a02c);
	border-radius: 6px 0 0 6px;
	transition: all 0.3s ease-in-out;
	animation: 1.6s ease-in-out ofc-solaire-preloader infinite alternate;
  }
  
  @keyframes ofc-solaire-preloader {
	0% {
	  transform: translate3d(0, 0, 0);
	  border-radius: 6px 0 0 6px;
	}
	20% {
	  border-radius: 0;
	}
	80% {
	  border-radius: 0;
	}
	100% {
	  transform: translate3d(100%, 0, 0);
	  border-radius: 0 6px 6px 0;
	}
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content-text {
	display: flex;
	text-transform: uppercase;
	font-weight: 600;
	color: #ac1f26;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content-string {
	overflow: hidden;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content-string--one {
	animation: 6.4s linear ofc-solaire-show-hide infinite;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content-string--two {
	height: 0;
	width: 0;
	animation: 6.4s 3.2s linear ofc-solaire-show-hide infinite;
  }
  
  @keyframes ofc-solaire-show-hide {
	0%,
	49.99% {
	  height: auto;
	  width: auto;
	}
	50%,
	100% {
	  height: 0;
	  width: 0;
	}
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content-dots {
	margin-left: 3px;
	display: flex;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content-dots-el:not(:first-child) {
	overflow: hidden;
	width: 0;
	height: 0;
  }
  
  .ofc-loader-solaire .ofc-loader-solaire__content-dots--first .ofc-loader-solaire__content-dots-el:nth-child(2),
  .ofc-loader-solaire .ofc-loader-solaire__content-dots--second .ofc-loader-solaire__content-dots-el:nth-child(3) {
	overflow: visible;
	width: auto;
	height: auto;
  }
  
  .ofc-page-loader__wrapper {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: #efefef;
  }
  
  .ofc-page-loader__background {
	position: absolute;
	width: 100%;
	height: 100%;
  }
  
  .ofc-page-loader__content {
	position: relative;
  }
  
  .ofc-page-loader__logo {
	/* position: absolute; */
	display: block;
	margin: -15px auto 0;
	/* left: 50%; */
	width: 100px;
	height: 100px;
	max-width: 100vw;
	object-fit: contain;
	/* transform: translate3d(-50%, -100%, 0); */
  }
  
  .ofc-page-loader__spinner {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	border: 3px solid #a0a0a0;
	border-left-color: transparent;
	border-radius: 50%;
	animation: 1s linear page-loader-animation infinite;
  }
  
  .ofc-page-loader__title {
	width: 300px;
	max-width: 100vw;
	text-align: center;
	font-family: sans-serif;
	font-size: 16px;
	color: #555555;
	margin: 15px 0 0;
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translate3d(-50%, 0, 0);
  }
  
  @keyframes page-loader-animation {
	0% {
	  transform: rotate3d(0, 0, 1, 0deg);
	}
	100% {
	  transform: rotate3d(0, 0, 1, 360deg);
	}
  }