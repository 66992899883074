ac-interaction,
ac-interaction-success,
ac-interaction-question,
ac-interaction-error,
ac-interaction-captcha {
    display: block;
}

ac-interaction-error {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
}

ac-interaction-error .btn {
    min-width: 200px;
}

ac-interaction-question .sound-toggle-btn {
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 100;
}

ac-interaction-question .alert,
ac-interaction-question .opt-in,
ac-interaction-question .privacy-notice {
    font-size: 14px;
}

ac-interaction-error .error-block {
    margin-top: 40px;
}

ac-interaction-error .error-block .alert {
    margin-bottom: 30px;
}

ac-interaction-captcha {
    text-align: center;
}

ac-interaction-captcha .captcha {
    display: inline-block;
}

.interaction-footer {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.interaction-footer .footer-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 15px;
    color: #7C858C;
}

.answer-select-image.has-error .answer-value-wrapper .img {
    border: 1px solid #a94442;
    webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-35 {
    margin-top: 35px;
}

#autoComplete_list {
    list-style: none;
    padding-left: 0;
    border: none;
    border-radius: 6px;
    margin-top: 6px;
}

#autoComplete_list li {
    padding-left: 17px;
    cursor: pointer;
    font-weight: 100;
    font-size: smaller;
    line-height: 1;
}

#autoComplete_list li:last-child {
    border-bottom-right-radius: 6px;
    padding-bottom: 6px;
}

#autoComplete_list li:first-child {
    padding-top: 50px;
}

.autocomplete-input-group {
    width: 100%;
}

.autocomplete-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/*

#autoComplete_wrapper {
    position: relative;
    display: inline-block;
}
#autoComplete {
    position: relative;
    padding: 0 2rem 0 3.5rem;
    height: 2.1rem;
    !*width: 6rem;*!
    font-size: 1.2rem;
    outline: none;
    border-radius: 50rem;
    border: 0.05rem solid rgb(255, 122, 122);
    caret-color: rgb(255, 122, 122);
    color: rgba(255, 255, 255, 0);
    color: black;
    !*background-image: url(./images/magnifier.svg);*!
    background-repeat: no-repeat;
    background-size: 1.2rem;
    background-origin: border-box;
    background-position: center;
    !*transition: all 0.4s ease;*!
}
#autoComplete::placeholder {
    color: rgba(255, 122, 122, 0);
    transition: all 0.3s ease;
}
#autoComplete:focus {
    padding: 0 2rem 0 3.5rem;
    color: rgba(255, 122, 122, 1);
    height: 3rem;
    width: 16.5rem;
    border: 0.05rem solid rgb(255, 122, 122);
    background-image: url(./images/magnifier.svg);
    background-size: 1.5rem;
    background-position: left 1.05rem top 0.8rem;
    box-shadow: rgba(255, 122, 122, 0.1) 0px 0px 20px 5px;
}
#autoComplete_list {
    !*osition: fixed;*!
    z-index: 1000;
    padding: 0;
    width: 18rem;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all -webkit-transform 0.1s ease;
}
.autoComplete_result {
    argin: 0.15rem auto;
    padding: 0.6rem;
    ax-width: 280px;
    border: 0.05rem solid #e3e3e3;
    list-style: none;
    text-align: left;
    font-size: 1.1rem;
    color: rgb(123, 123, 123);
    transition: all 0.1s ease-in-out;
    background-color: #fff;
}
@media only screen and (max-width: 600px) {
    #autoComplete {
        width: 5rem;
    }
    #autoComplete:focus {
        width: 11rem;
        background-size: 1.6rem;
        background-position: left 1.1rem top 0.75rem;
    }
    .autoComplete_result:first-child {
        border-radius: 1rem 1rem 0 0;
    }
    .autoComplete_result:last-child {
        border-radius: 0 0 1rem 1rem;
    }
    .autoComplete_result:only-child {
        border-radius: 1rem;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    #utoComplete {
        border-width: 1px;
    }
    #autoComplete:hover {
        border-width: 1px;
    }
    #autoComplete:focus {
        border-width: 1px;
    }
}
@-moz-document url-prefix() {
    #autoComplete {
        border-width: 1px;
        background-size: 1.2rem;
        background-origin: border-box;
        background-position: center;
    }
    #autoComplete:hover {
        border-width: 1px;
    }
    #autoComplete:focus {
        border-width: 1px;
        background-position: left 1.1rem top 0.8rem;
    }
}*/
.submit-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .40;
    z-index: 100;
    background-color: #d3d3d3;
    display: flex;
    align-items: center;
    justify-content: center;
}
