/* Pick'em Game */

.pickem-game {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #E5E5E5;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: auto;
}

.pickem-game * {
    box-sizing: border-box;
}

.pickem-game-header {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: center;
    background-size: contain;
    white-space: nowrap;
}

.pickem-game-header:before {
    content: "";
    display: inline-block;
    height: 80px;
    vertical-align: middle;
}

.pickem-game-header img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 160px;
}

@media (max-width: 520px) {
    .pickem-game-header .title {
        font-size: 9vw;
    }
}

.pickem-game-main {
    display: block;
    max-width: 1024px;
    margin: 0 auto;
}

.pickem-game-main:before,
.pickem-game-main:after {
    content: "";
    display: table;
    clear: both;
}

.pickem-game .questions-list {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
}

.pickem-game .btn-submit-row {
    margin: 20px 0;
    text-align: center;
}

.pickem-game .btn-submit {
    height: 80px;
    margin: 0;
    padding: 0 25px;
    color: #FFFFFF;
    font-family: Georgia Arial;
    font-size: 48px;
    line-height: 80px;
    font-weight: 700;
    white-space: nowrap;
    border-radius: 40px;
    border: none;
    text-transform: uppercase;
}

.pickem-game .btn-submit.has-image {
    height: auto;
    padding: 0;
    border-radius: 0;
    background: none!important;
}

.pickem-game .btn-submit.has-image img {
    display: block;
}

.pickem-game .btn-submit,
.pickem-game .btn-submit:hover,
.pickem-game .btn-submit[disabled] {
    background-color: #4041FE;
}

.pickem-game .week-day {
    display: block;
    margin: 16px 0;
    font-family: Arial, Helvetica;
    font-size: 28px;
    line-height: 1em;
    font-weight: 400;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
}

.pickem-game .pickem-question {

}

.pickem-game .disabled{

}

.pickem-question .pickem-question-panel {
    position: relative;
    background-color: #FFFFFF;
    height: 140px;
}

.disabled .pickem-question-panel {
    height: 120px;
    background-color: #ececec !important;
    margin: 13px auto;
}

.pickem-question .time {
    position: absolute;
    top: 0;
    left: 50%;
    height: 40px;
    width: 90px;
    margin: 0 0 0 -45px;
    background-color: #FFFFFF;
    border: 2px solid #E5E5E5;
    border-top-width: 1px;
    color: #E5E5E5;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    font-weight: 400;
}

.disabled .time{
    color:#b3b3b3;
}

.pickem-question.time-hidden .time {
    display: none;
}

.pickem-question .pickem-question-row {
    height: 100%;
    overflow: hidden;
}

.pickem-question .pickem-question-row .col {
    position: relative;
    float: left;
    width: 50%;
    height: 100%;
    border: 1px solid #E5E5E5;
    background: rgba(204,204,204,0);
    text-align: center;
    cursor: pointer;
}

.disabled .pickem-question-row .col {
    cursor: default;
}

.pickem-question .pickem-question-row .col-2 {
    float: right;
}

.pickem-question .pickem-question-row .arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    margin-top: 14px;
    border-top: 2px solid #E5E5E5;
    border-right: 2px solid #E5E5E5;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    z-index: 1;
}

.pickem-question .pickem-question-row .col-1 .arrow {
    right: 5px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(45deg);
}

.pickem-question .pickem-question-row .col-2 .arrow {
    left: 5px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.pickem-question .place {
    position: absolute;
    top: 8px;
    color: #E5E5E5;
    font-size: 14px;
    line-height: 1em;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (min-width: 600px) {
    .pickem-question .place {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    .pickem-question .place {
        font-size: 18px;
    }
}

.pickem-question .col-1 .place {
    left: 8px;
    right: 45px;
    text-align: left;
}

.pickem-question.time-hidden .col-1 .place {
    right: 8px;
}

.pickem-question .col-2 .place {
    right: 8px;
    left: 45px;
    text-align: right;
}

.pickem-question.time-hidden .col-2 .place {
    left: 8px;
}

.pickem-question .team-name {
    display: table;
    width: 100%;
    height: 100%;
    color: #040404;
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 400;
}

.pickem-question .team-name > span {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 2px 15px;
}

.pickem-question .team-name-img-wrapper {
    position: absolute;
    left: 10px;
    right: 15px;
    top: 45px;
    bottom: 35px;
}

.pickem-question .team-name-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.pickem-question .team-achievements {
    position: absolute;
    left: 4px;
    right: 4px;
    bottom: 8px;
    text-align: center;
    color: #555555;
    font-size: 14px;
    line-height: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

@media (min-width: 600px) {
    .pickem-question .team-achievements {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    .pickem-question .team-achievements {
        font-size: 20px;
    }
}

.pickem-question .team-achievements .partaker-standings {
    font-weight: normal;
}

.pickem-question .team-name-img img,
.pickem-question .team-name-img image,
.pickem-question .team-name-img svg {
    pointer-events: none;
}

.pickem-question.animation-enabled .time,
.pickem-question.animation-enabled .col,
.pickem-question.animation-enabled .place,
.pickem-question.animation-enabled .team-name > span,
.pickem-question.animation-enabled .team-achievements,
.pickem-question.animation-enabled .team-name-img-wrapper,
.pickem-question.animation-enabled .team-name-img,
.pickem-question.animation-enabled .team-name-img image{
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.svg-grayscale-filter {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    left: -100px;
    top: -100px;
}