.campaign-lobby {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.campaign-lobby .campaign-lobby-inner {
    display: block;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.campaign-lobby .panel-heading {
    margin: 0;
    padding: 0 15px;
}

.campaign-lobby .panel {
    margin-top: 30px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.campaign-lobby .question-panel {
    border-radius: 4px;
}

.campaign-lobby .question-panel > .panel-body > .question-content {
    max-width: 1000px;
}

.campaign-lobby .panel-heading .client-logo-img {
    display: block;
    max-height: 100px;
    max-width: 100%;
    margin: 0 auto;
    float: none;
}

.campaign-lobby .standard-campaigns {
    text-align: center;
	margin-top: 15px;
    margin-right: -15px;
    margin-left: -15px;
}

.campaign-lobby .standard-campaigns .lobby-item {
    display: block;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    /* XS */
    .campaign-lobby .standard-campaigns .lobby-item {
        width: 100%;
    }
}

@media (min-width: 768px) {
    /* SM */
    .campaign-lobby .standard-campaigns .lobby-item {
        display: inline-block;
        width: 50%;
    }
}

@media (min-width: 992px) {
    /* MD */
    .campaign-lobby .standard-campaigns .lobby-item {
        width: 50%;
    }

    .campaign-lobby .standard-campaigns.campaigns-length-3-and-more .lobby-item {
        width: 33%;
    }
}

@media (min-width: 1200px) {
    /* LG */
    .campaign-lobby .standard-campaigns .lobby-item {
        width: 50%;
    }

    .campaign-lobby .standard-campaigns.campaigns-length-3-and-more .lobby-item {
        width: 33%;
    }

    .campaign-lobby .standard-campaigns.campaigns-length-4-and-more .lobby-item {
        width: 25%;
    }
}

.campaign-lobby .panel-title {
    font-size: 2.1em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    color: #aaaaaa;
}

.campaign-lobby .standard-campaigns .well {
    position: relative;
    padding: 18px;
    margin: 0;
    border: 3px outset #EEE;
}

.campaign-lobby .standard-campaigns .lobby-item-image-box {
    position: relative;
    padding: 75% 0 0 0;
    cursor: pointer;
    overflow: hidden;
}

.campaign-lobby .lobby-item-disabled {
    cursor: default !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    -khtml-opacity: 0.4;
    opacity: 0.4;}

.campaign-lobby .standard-campaigns .image-stamp-wrapper,
.campaign-lobby .standard-campaigns .lobby-item-image {
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    margin: auto;
    max-width: 98%;
    max-height: 98%;

}

.campaign-lobby .standard-campaigns .image-stamp-wrapper .img {
    height: 100%;
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .5);
    box-shadow: 0 0 2px rgba(0, 0, 0, .5);
}

.campaign-lobby .standard-campaigns .lobby-item-title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 1;
}

.campaign-lobby .standard-campaigns .lobby-actions {
    display: block;
    margin-top: 10px;
    text-align: left;
}

.campaign-lobby .standard-campaigns .lobby-actions .btn {
    margin-left: 5px;
}

.campaign-lobby .standard-campaigns .lobby-actions .btn:first-child {
    margin-left: 0;
}

.campaign-lobby .standard-campaigns .campaign-info,
.campaign-lobby .standard-campaigns .campaign-description,
.campaign-lobby .standard-campaigns .campaign-tc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70%;
    padding: 10px;
    background: rgba(255, 255, 255, .9);
    overflow: auto;
    text-align: left;
}

.campaign-lobby .standard-campaigns .campaign-info .lobby-overlay-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.campaign-lobby .standard-campaigns .campaign-info h4 {
    font-size: 14pt;
    margin: 5px;
}

.campaign-lobby .standard-campaigns .campaign-info.ng-hide-add.ng-hide-add-active,
.campaign-lobby .standard-campaigns .campaign-info.ng-hide-remove.ng-hide-remove-active {
    -webkit-transition: all linear 0.5s;
    transition: all linear 0.5s;
}

.campaign-lobby .standard-campaigns .campaign-info.ng-hide {
    top: 312px;
}

.campaign-lobby .standard-campaigns .campaign-info.ng-hide-add.ng-hide-add-active {
    top: -312px;
}

.campaign-lobby .buttons-row {
    position: relative;
    margin: 35px 0 15px;
    text-align: center;
    z-index: 1;
}

/* Action buttons */

@media (max-width: 460px) {
    .campaign-lobby .standard-campaigns .lobby-actions .btn {
        display: block;
        width: 100%;
        float: none !important;
        margin: 10px 0 0;
    }

    .campaign-lobby .standard-campaigns .lobby-actions .btn:first-child {
        margin: 0;
    }

    .reward-modal .modal-dialog {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        overflow-y: hidden;
    }
}

@media (min-width: 992px) and (max-width: 1170px) {
    .campaign-lobby .standard-campaigns.campaigns-length-3-and-more .lobby-actions .btn {
        display: block;
        width: 100%;
        float: none !important;
        margin: 10px 0 0;
    }

    .campaign-lobby .standard-campaigns.campaigns-length-3-and-more .lobby-actions .btn:first-child {
        margin: 0;
    }
}

@media (min-width: 1200px) and (max-width: 1560px) {
    .campaign-lobby .standard-campaigns.campaigns-length-4-and-more .lobby-actions .btn {
        display: block;
        width: 100%;
        float: none !important;
        margin: 10px 0 0;
    }

    .campaign-lobby .standard-campaigns.campaigns-length-4-and-more .lobby-actions .btn:first-child {
        margin: 0;
    }
}

@media (min-width: 460px) and (max-width: 1200px) {
    .campaign-lobby .standard-campaigns .lobby-actions .btn {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }
}

@media (max-width: 1800px) {
    .campaign-lobby .standard-campaigns.campaigns-length-4-and-more .lobby-actions .btn {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
    }

    @media (max-width: 1600px) {
        .campaign-lobby .standard-campaigns.campaigns-length-3-and-more .lobby-actions .btn {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 3px;
        }
    }
}

/*------------------------------------*/

.campaign-lobby-start-screen .carousel-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 10%;
}

.campaign-lobby-start-screen .carousel div.slide-background-img {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.campaign-lobby-start-screen .carousel {
    position: absolute;
    width: 100%;
    height: 100%;
}

.campaign-lobby-start-screen .carousel-control,
.campaign-lobby-start-screen .carousel-indicators {
    display: none;
}

.campaign-lobby-start-screen .carousel-inner,
.campaign-lobby-start-screen .carousel .item {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    /*font-size: 0;*/
}

.campaign-lobby-start-screen .carousel .slide-img,
.campaign-lobby-start-screen .carousel video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
}

.campaign-lobby-start-screen .carousel .video-wrapper {
    position: static;
}

.campaign-lobby-start-screen .carousel .alert {
    position: relative;
    z-index: 1;
}

.campaign-lobby-start-screen .btn-start-row {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    height: auto;
}

.campaign-lobby-start-screen .btn-start-row img {
    height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.campaign-lobby-start-screen .btn-start {
    position: absolute;
    height: 60%;
    width: 60%;
    bottom: 20%;
    left: 20%;
    border-radius: 10px;
    min-height: 50px;
    text-transform: uppercase;
}

.campaign-lobby-start-screen .vimeo-no-pause,
.campaign-lobby-start-screen .youtube-no-pause {
    position: absolute;
    top: 0;
    background: transparent;
    z-index: 1000;
    height: 100%;
    width: 100%;
}

.back-here-checkbox {
    margin: 0 auto;
    padding: 10px;
    cursor: pointer;
    width: 220px;
    background: #FFF;
    border: 1px solid black;
}

@media (min-width: 768px) {
    .campaign-lobby-start-screen .btn-start {
        font-size: 3.2vmin;
    }
}

.campaign-lobby-start-screen .user-block {
    width: 150px;
    border: 1px solid lightgrey;
}

@media screen and (max-width: 390px) {
    .campaign-lobby-start-screen .user-block {
        width: 100%;
    }
}

    @media screen and (max-width: 390px) {
        .campaign-lobby-start-screen .user-block-wrapper {
            justify-content: center;
            width: 100%;
            display: flex;
            margin-bottom: 0 !important;
        }
    }

.campaign-lobby-start-screen .user-login-wrapper .user-controls .glyphicon {
    display: none;
}

@media screen and (max-width: 390px) {
    .campaign-lobby-start-screen .user-login-wrapper .user-controls .glyphicon {
        display: inline-block;
        margin-right: 5px;
    }

    .campaign-lobby-start-screen .user-login-wrapper .user-non-active {
        opacity: 1;
        background-color: white;
    }

    .campaign-lobby-start-screen .user-login-wrapper .user-non-active .user-controls {
        background-color: white !important;
    }
}


    .campaign-lobby-start-screen .btnd:active, .btnd:focus, .btnd.active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .campaign-lobby-start-screen .user-active {
        background-color: lightgreen;
    }

    .campaign-lobby-start-screen .user-non-active {
        transition: all 0.4s;
        background-color: lightgray;
        opacity: 0.5;
    }

@media screen and (max-width: 390px) {
    .campaign-lobby-start-screen .user-active .user-controls {
        background-color: lightgreen !important;
    }

    .campaign-lobby-start-screen .user-non-active .user-controls {
        transition: all 0.4s;
        background-color: lightgray !important;
        opacity: 0.5;
    }
}

        .campaign-lobby-start-screen .user-non-active:hover {
            transition: all 0.4s;
            background-color: lightgreen;
            opacity: 1;
        }

    .campaign-lobby-start-screen .user-block .name-abbreviation {
        height: 120px;
        text-align: center;
        vertical-align: middle;
        font-size: 50px;
        padding-top: 30px;
    }

    @media screen and (max-width: 390px) {
        .campaign-lobby-start-screen .user-block .name-abbreviation {
            display: none;
        }
    }

    .campaign-lobby-start-screen .user-block .name-abbreviation span {
        color: dimgrey;
    }

    .campaign-lobby-start-screen .user-block .user-name {
        text-align: left;
        width: 100%;
        padding: 5px 5px 0 5px;
        margin-bottom: 0;
        margin-top: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

@media screen and (max-width: 390px) {
    .campaign-lobby-start-screen .user-block .user-name {
        font-size: 13px;
    }
}

    .campaign-lobby-start-screen .user-block .user-logout {
        text-align: right;
        padding: 5px 5px 0px 5px;
        width: 100%;
        margin-bottom: 0;
    }

    .campaign-lobby-start-screen .user-block .user-controls .user-logout a {
        color: darkgrey;
    }

@media screen and (max-width: 390px) {
    .campaign-lobby-start-screen .user-block .user-controls .user-logout a {
        color: dimgrey;
    }
}

    .campaign-lobby-start-screen .user-block .user-controls .user-logout-controls {
        margin-top: 2px;
        width: 100%;
    }

    .campaign-lobby-start-screen .user-block .user-controls .user-logout-controls button {
        border-radius: 0;
        border-bottom: none;
    }

    .campaign-lobby-start-screen .user-block .user-controls .user-logout-controls button:first-child {
        border-left: none;
    }

    .campaign-lobby-start-screen .user-block .user-controls .user-logout-controls button:last-child {
        border-right: none;
    }

    .campaign-lobby-start-screen .user-block .user-controls .user-login {
        padding-top: 18px;
        margin-top: 0;
    }

    .campaign-lobby-start-screen .user-block .user-controls {
        width: 100%;
        background-color: white;
        border-top: 1px solid lightgrey;
        height: 50px;
    }

    @media screen and (max-width: 390px) {
        .campaign-lobby-start-screen .user-block .user-controls {
            border-top: none;
        }
    }

    .campaign-lobby-start-screen .auth-buttons {
        display: inline-block;
    }

    .campaign-lobby-start-screen .btn-logout-wrapper {
        display: grid;
    }

    .campaign-lobby-start-screen .btn-user {
        margin-right: 10px;
    }

    .campaign-lobby-start-screen .lobby-users {
        margin: 20px 0 20px 0;
    }

        .campaign-lobby-start-screen .lobby-users .btn {
        }

    .campaign-lobby-start-screen .btn {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px) {
        .campaign-lobby-start-screen .auth-buttons {
            display: inline-block;
            text-align: center;
            margin: 50px 0 15px;
        }
    }

    .campaign-lobby-start-screen .btn-login {
        display: inline-block;
        min-width: 120px;
        margin-right: 15px;
    }

    /*Pickem Campaigns*/
    .campaign-lobby .pickem-campaigns {
        display: block;
        width: 520px;
        max-width: 100%;
        margin: 15px auto 0;
    }

        .campaign-lobby .pickem-campaigns .lobby-item {
            position: relative;
            cursor: pointer;
            display: block;
            min-height: 100%;
            padding: 5px;
            border: 1px solid #d5d5d5;
            border-radius: 3px;
            -webkit-transition: all 0.3s;
            -ms-transition: all 0.3s;
            transition: all 0.3s;
        }

            .campaign-lobby .pickem-campaigns .lobby-item:not(.disabled):hover,
            .campaign-lobby .pickem-campaigns .lobby-item:not(.disabled):focus {
                border-color: #b5b5b5;
                box-shadow: 0 0 4px rgba(0,0,0,0.2);
            }

            .campaign-lobby .pickem-campaigns .lobby-item:after {
                content: "";
                display: table;
                clear: both;
            }

            .campaign-lobby .pickem-campaigns .lobby-item.disabled:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                cursor: default;
                background: rgba(255,255,255,0.75);
            }

        .campaign-lobby .pickem-campaigns .lobby-item-image-wrapper {
            float: left;
            margin: 0 15px 0 0;
            width: 30%;
        }

        .campaign-lobby .pickem-campaigns .lobby-item-image-box {
            position: relative;
            padding: 75% 0 0 0;
        }

        .campaign-lobby .pickem-campaigns .lobby-item-image {
            position: absolute;
            left: 2px;
            right: 2px;
            top: 2px;
            bottom: 2px;
            margin: auto;
            max-width: 98%;
            max-height: 98%;
            -moz-box-shadow: 0 0 2px rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .5);
            box-shadow: 0 0 2px rgba(0, 0, 0, .5);
        }

        .campaign-lobby .pickem-campaigns .col {
            display: block;
            overflow: hidden;
        }

        .campaign-lobby .pickem-campaigns .lobby-item-title {
            margin: 12px 0;
        }

    /*Coupon Campaigns*/

    .campaign-lobby .coupon-campaigns {
        display: block;
        width: 290px;
        margin: 15px auto 0;
    }

    .campaign-lobby .coupon-campaigns.coupon-with-pagination {
        width: 80%;
    }

    .campaign-lobby .coupon-campaigns.coupon-with-pagination .coupon-item {
        width: 140px;
        float: left;
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .campaign-lobby .coupon-campaigns .coupon-item {
        margin-bottom: 30px;
        text-align: center;
        cursor: pointer;
    }

    .campaign-lobby .coupon-campaigns .coupon-item:focus {
        outline: 0;
    }
        .campaign-lobby .coupon-campaigns .campaign-item {
            position: relative;
            display: block;
            height: 116px;
            margin: 0 0 30px;
            padding: 5px;
            border: 1px solid #d5d5d5;
            border-radius: 6px;
            overflow: hidden;
            -webkit-perspective: 800;
            -moz-perspective: 800;
            -ms-perspective: 800;
            perspective: 800;
            -ms-transform: perspective(800px);
            -moz-transform: perspective(800px);
            -moz-transform-style: preserve-3d;
            -ms-transform-style: preserve-3d;
        }

            .campaign-lobby .coupon-campaigns .campaign-item .campaign-item-inner {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                -ms-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transition: 0.5s;
                -moz-transition: 0.5s;
                transition: 0.5s;
            }

            .campaign-lobby .coupon-campaigns .campaign-item .front,
            .campaign-lobby .coupon-campaigns .campaign-item .back {
                position: absolute;
                width: 100%;
                height: 100%;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                -ms-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transition: 0.5s;
                -moz-transition: 0.5s;
                transition: 0.5s;
                border-radius: 6px;
                overflow: hidden;
            }

            .campaign-lobby .coupon-campaigns .campaign-item .front {
                z-index: 1;
            }

            .campaign-lobby .coupon-campaigns .campaign-item .back {
                -webkit-transform: rotateX(-180deg);
                -moz-transform: rotateX(-180deg);
                transform: rotateX(-180deg);
                cursor: pointer;
                z-index: 2;
            }

            .campaign-lobby .coupon-campaigns .campaign-item .back.cancelled {
                cursor: auto;
            }

            .campaign-lobby .coupon-campaigns .campaign-item .flipped .front {
                -webkit-transform: rotateX(-180deg);
                -moz-transform: rotateX(-180deg);
                transform: rotateX(-180deg);
            }

            .campaign-lobby .coupon-campaigns .campaign-item .flipped .back {
                -webkit-transform: rotateX(0deg);
                -moz-transform: rotateX(0deg);
                transform: rotateX(0deg);
            }

        .campaign-lobby .coupon-campaigns .campaign-holder,
        .campaign-lobby .coupon-campaigns .reward-holder {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .campaign-lobby .coupon-campaigns .campaign-holder {
            background-color: #fff;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
        }

            .campaign-lobby .coupon-campaigns .campaign-holder .play-btn {
                position: absolute;
                width: 80px;
                height: 80px;
                top: 50%;
                margin-top: -40px;
                right: 15px;
            }

            .campaign-lobby .coupon-campaigns .campaign-holder .img-play-icon {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }

        .campaign-lobby .coupon-campaigns .reward-holder {
            padding: 10px;
            background: #fff;
            background: -webkit-linear-gradient(#fff 0%, #D6DEE3 50%, #fff 100%);
            background: -o-linear-gradient(#fff 0%, #D6DEE3 50%, #fff 100%);
            background: -moz-linear-gradient(#fff 0%, #D6DEE3 50%, #fff 100%);
            background: linear-gradient(#fff 0%, #D6DEE3 50%, #fff 100%);
            text-align: center;
        }

            .campaign-lobby .coupon-campaigns .reward-holder .col-info {
                position: relative;
                float: left;
                width: 54%;
                height: 100%;
                color: #000;
                font-family: Arial;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .col-img {
                position: relative;
                float: right;
                width: 42%;
                height: 100%;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .reward-code-text {
                display: block;
                font-size: 16px;
                line-height: 1em;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .bottom-row {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .add-info-txt {
                margin: 0 0 2px;
                font-size: 10px;
                line-height: 10px;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .reward-name {
                display: block;
                font-size: 14px;
                line-height: 1em;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: uppercase;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .reward-img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .poscode,
            .fancy-spinner-box,
            .fancy-spinner-box .fancy-spinner-content {
                display: block;
                width: 100%;
                height: 100%;
            }

            .campaign-lobby .coupon-campaigns .reward-holder .poscode {
                position: relative;
            }

                .campaign-lobby .coupon-campaigns .reward-holder .poscode canvas {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                }

    .coupon-campaigns-is-active .campaign-lobby .client-logo-img {
        max-height: 100%;
        max-width: 290px;
    }

    /**/

    @media (max-width: 767px) {
        .reward-modal-box .modal-body {
            height: calc(100vh - 75px);
            overflow: hidden;
        }

        .reward-modal-box .modal-body-inner {
            height: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        .reward-modal-box reward {
            display: block;
            overflow: hidden;
        }
    }

    @media (max-width: 460px) {
        .reward-modal-box .modal-body {
            height: calc(85vh - 85px);
            overflow-y: auto;
            -webkit-overflow-scrolling: auto;
        }

        .reward-modal-box .modal-body-inner {
            min-height: calc(100% + 1px);
            overflow: visible;
        }

        body.is-mobile.modal-open {
            -webkit-overflow-scrolling: touch;
        }
    }

    .reward-modal-box .modal-footer {
        padding: 10px;
    }

    .reward-modal-box .reward-info {
        display: block;
        /*overflow: hidden;*/
    }

.coupon-item.wiggle {
    animation: wiggle 3s 0s infinite cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: visible;
    perspective: 1000px;
}

@keyframes wiggle {
    42%, 58% {
        transform: translate3d(-1px, 0, 0);
    }

    44%, 56% {
        transform: translate3d(2px, 0, -2px);
    }

    46%, 50%, 54% {
        transform: translate3d(-4px, -2px, 0);
    }

    48%, 52% {
        transform: translate3d(4px, 2px, 0);
    }
}

.lobby-message {
    text-align: center;
}

{
    position: relative;
}

.right-inner-addon input,
.tool-item .right-inner-addon input {
    padding-right: 30px;
}

.right-inner-addon span {
    position: absolute;
    right: 0px;
    padding: 10px 22px;
    font-size: 90%;
    cursor: pointer;
    z-index: 20;
    top: -1px;
    color: #999;
}

.right-inner-addon input[type=text] {
    width: 150px;
    padding-right: 26px;
}

.coupon-title {
    font-size: 12px;
}

.coupon-with-pagination .pagination {
    margin: 0;
}

.coupon-with-pagination>.row.coupon-pagination {
    margin-bottom: 20px;
}

@media (max-width: 414px) {
    .coupon-with-pagination .pagination {
        float: right;
        margin-bottom: 10px;
    }
}

.coupon.panel-footer {
    background-color: unset;
    border-top: none;
}

.coupon-tabs {
    margin-top: 10px;
}

.coupon-tabs-container {
    position: absolute;
    background: rgba(255,255,255,0.75);
    z-index: 99;
    bottom: 0;
    max-width: 320px;
    margin: auto;
    left: 0;
    right: 0;
}

.coupon-tabs-container>div {
    padding-left: 0;
}

.coupon-tabs li {
    display: table-cell;
    width: 1%;
    background: rgb(255, 255, 255);
}

.coupon-tabs li>a:hover, .coupon-tabs li>a:focus, .coupon-tabs li>a:active {
    background-color: unset;
}

.coupon-tabs li:not(.active) {
   box-shadow: -3px -3px 10px -7px rgba(0, 0, 0, 0.5);
}

.coupon-tabs li:not(.active)>a {
    border-bottom: none;
}

.coupon-tabs li a {
    text-transform: uppercase;
}

.bar-statuses .campaign-lobby {
    padding-bottom: 45px;
}

.account-activation-iframe {
    width: 100%;
}

.campaign-dates{
    position: relative;
}

.lobby-description-close {
    display: none;
    cursor: pointer;
}

.coupon-reward-code {
    display: none;
}

.description p, .description .coupon-reward-code {
    margin-left: 20px;
}

.hiddenDates {
    visibility: hidden;
}