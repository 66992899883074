::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: #eeeeee;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eaeaea;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}

.dashboard-page {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: auto;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    color: #4b4e50;
    font-size: 14px;
    line-height: 1.1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.dashboard-backgroundVideo {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.dashboard-backgroundVideo video {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.dashboard-backgroundVideo .vimeo-no-pause,
.dashboard-backgroundVideo .youtube-no-pause {
    position: absolute;
    top: 0;
    background: transparent;
    z-index: 1000;
    height: 100%;
    width: 100%;
}

.dashboard-container {
    height: 100%;
    max-width: 1370px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.dashboard-panel {
    display: block;
    height: 100%;
}

@media (max-width: 420px) {
    .dashboard-panel {
        font-size: 12px;
    }
}

.dashboard-panel .dashboard-panel-title {
    display: block;
    font-size: 2.57em;
    text-align: center;
    text-transform: uppercase;
}

.dashboard-panel .dashboard-panel-body {
    display: block;
}

.dashboard-panel .dashboard {
    overflow: hidden;
}

.dashboard-panel .dashboard .dashboard-col {
    display: block;
    margin: 0 10px;
}

.dashboard-panel .dashboard .dashboard-col > [class^="dashboard-box"] {
    position: relative;
    display: block;
    margin: 10px 0;
    padding: 0;
    border-radius: 10px;
    min-height: 80px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0.85);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

@media (min-width: 992px) {
    .dashboard-panel {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -ms-flex-direction: column;
        flex-direction: column;
    }

    .dashboard-panel .dashboard-panel-body {
        position: relative;
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }

    .dashboard-panel .dashboard {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .dashboard-panel .dashboard .dashboard-col {
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;

        display: -ms-flexbox;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -ms-flex-direction: column;
        flex-direction: column;

        height: 100%;
    }

    .dashboard-panel .dashboard .dashboard-col > [class^="dashboard-box"] {
        height: 100%;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .dashboard-panel .dashboard-fiveBoxes,
    .dashboard-panel .dashboard-sixBoxes {
        min-height: 280px;
    }

    .dashboard-panel .dashboard-twoBoxes > .dashboard-col:first-child {
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }

    .dashboard-panel .dashboard-twoBoxesLeftSidebar > .dashboard-col:first-child {
        -webkit-flex: 0.5 0.5 0px;
        -ms-flex: 0.5 0.5 0px;
        flex: 0.5 0.5 0px;
    }

    .dashboard-panel .dashboard-twoBoxesRightSidebar > .dashboard-col:first-child {
        -webkit-flex: 2 2 0px;
        -ms-flex: 2 2 0px;
        flex: 2 2 0px;
    }

    .dashboard-panel .dashboard-fiveBoxes .dashboard-col > .dashboard-box-2,
    .dashboard-panel .dashboard-fiveBoxes .dashboard-col > .dashboard-box-5,
    .dashboard-panel .dashboard-sixBoxes .dashboard-col > .dashboard-box-1,
    .dashboard-panel .dashboard-sixBoxes .dashboard-col > .dashboard-box-3,
    .dashboard-panel .dashboard-sixBoxes .dashboard-col > .dashboard-box-5 {
        -webkit-flex: 0.5 0.5 61%;
        -ms-flex: 0.5 0.5 61%;
        flex: 0.5 0.5 61%;
    }

    .dashboard-panel .dashboard-fiveBoxes .dashboard-col > .dashboard-box-3,
    .dashboard-panel .dashboard-fiveBoxes .dashboard-col > .dashboard-box-4,
    .dashboard-panel .dashboard-sixBoxes .dashboard-col > .dashboard-box-2,
    .dashboard-panel .dashboard-sixBoxes .dashboard-col > .dashboard-box-4,
    .dashboard-panel .dashboard-sixBoxes .dashboard-col > .dashboard-box-6 {
        -webkit-flex: 0.5 0.5 34%;
        -ms-flex: 0.5 0.5 34%;
        flex: 0.5 0.5 34%;
    }
}

.dashboard-box > .dashboard-box-inner {
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 25px;
    overflow: auto;

    /* break-word is unofficial.  Nnot supported by IE or Firefox */
    /*word-break: break-all;
    word-break: break-word;*/
}

@media (min-width: 992px) {
    .dashboard-box > .dashboard-box-inner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.dashboard-box > .dashboard-box-inner > div {
    height: 100%;
    width: 100%;
    transform-origin: 50% 0;
    backface-visibility: hidden;
    /*-webkit-filter: blur(0px);*/
    -ms-transform: none !important; /*Disable Scale in IE*/
}

/* To fix content blurring in chrome */
.dashboard-box > .dashboard-box-inner > div {
    /*-webkit-animation: webkit-filter 1s infinite;*/
}

@-webkit-keyframes webkit-filter {
    from {
        -webkit-filter: blur(0.1px);
    }
    to {
        -webkit-filter: blur(0px);
    }
}
/**/

@media (max-width: 991px) {
    .dashboard-box > .dashboard-box-inner > div {
        transform: none !important;
    }
}

.dashboard-box-item .box-header {
    margin: 0 0 20px;
    /*overflow: hidden;*/
}

.dashboard-box-item .box-title {
    margin: 0;
    text-align: center;
    color: #b31d14;
    font-size: 1.2em;
    text-transform: uppercase;
}

.dashboard-box-item .box-subtitle {
    margin: 10px 0 0;
    text-align: center;
    color: #807d7c;
    text-transform: uppercase;
    font-size: 0.85em;
}

.campaign-dashboard-box {
    height: 100%;
}

.campaign-dashboard-box .box-body {
    position: relative;
    padding: 100% 0 0;
}

.campaign-dashboard-box .campaign-preview-frame {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    min-width: 100%;
    min-height: 100%;
    z-index: 1;
}

@media (min-width: 992px) {
    .campaign-dashboard-box .dashboard-box-item {
        height: 100%;

        display: -ms-flexbox;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -ms-flex-direction: column;
        flex-direction: column;
    }

    .campaign-dashboard-box .dashboard-box-item .box-body {
        padding: 0;

        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }
}

.video-dashboard-box .dashboard-box-item {
    height: 100%;
    overflow: hidden;
}

.video-dashboard-box .box-body {
    height: 100%;
    white-space: nowrap;
}

.video-dashboard-box .box-body:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.video-dashboard-box .video-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}

.html-dashboard-box .box-body {
    font-size: 1.142em;
    line-height: 1.2;
    overflow: hidden;
    height: 100%;
}

.html-dashboard-box .box-body p {
    margin: 14px 0;
}

.html-dashboard-box .box-body img {
    max-width: 100% !important;
}

.dashboard-box-item .countdown {
    margin: 20px 0;
    text-align: center;
}

.show-more-button {
    margin-top: 20px;
}

@media (max-width: 992px) {
    .show-more-button {
    }
}

.hide-more-button {
    display: none;
}

/*Leaderboard*/

.top-leader .leader-item.leader-header strong,
.list-leaders .leader-item.leader-header strong {
    font-size: 1.5em;
    font-weight: 700;
}

.list-leaders {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-leaders > li {
    margin: 12px 12px 0 0;
}

.list-leaders > li:first-child {
    margin-top: 0;
}

.list-leaders .leader-item {
    display: table;
    width: 100%;
}

.list-leaders .place,
.list-leaders .value,
.list-leaders .img-wrapper,
.list-leaders .name {
    display: table-cell;
    vertical-align: middle;
}

.list-leaders .place,
.list-leaders .value {
    width: 1%;
    min-width: 25px;
    white-space: nowrap;
    font-size: 1.43em;
    /*color: #222222;*/
    text-align: right;
}

.list-leaders .img-wrapper {
    padding-left: 3.7%;
    width: 18.5%;
}

@media (min-width: 992px) {
    /*.list-leaders .img-wrapper {
        padding-left: 10px;
        width: 62px;
    }*/
}

.list-leaders .img-wrapper img {
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
}

.list-leaders .name {
    padding: 0 0 0 20px;
    font-size: 1.28em;
    font-weight: 400;
}

.list-leaders .name.no-avatar {
    padding-left: 80px;
}

.list-leaders .value {
    padding: 0 0 0 10px;
}

/* three-leaders-and-list */

.three-leaders-and-list .countdown {
    margin: 25px 0;
    perspective: 100px;
}

.three-leaders-and-list .list-top-leaders {
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    text-align: center;
}

.three-leaders-and-list .list-top-leaders > li {
    display: inline-block;
    width: 33%;
    padding: 0;
    white-space: normal;
    vertical-align: bottom;
}

.three-leaders-and-list .list-top-leaders > li:first-child {
    width: 33%;
}

.three-leaders-and-list .list-top-leaders > li:first-child + li {
    width: 25%;
    margin: 0 11%;
}

.three-leaders-and-list .list-top-leaders > li:first-child + li + li {
    width: 20%;
}

.three-leaders-and-list .list-top-leaders figure {
    text-align: center;
}

.three-leaders-and-list .list-top-leaders .img-wrapper {
    display: block;
    overflow: hidden;
    border-radius: 50%;
}

.three-leaders-and-list .list-top-leaders .img-wrapper img {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
}

.three-leaders-and-list .list-top-leaders figcaption {
    margin: 8px 0 0;
}

.three-leaders-and-list .list-top-leaders .place {
    display: block;
    font-size: 2em;
    font-weight: 400;
    /*color: #222222;*/
}

.three-leaders-and-list .list-top-leaders .name {
    display: block;
    margin-top: 2px;
    font-size: 1.28em;
    line-height: 1.25em;
    font-weight: 400;
}

.three-leaders-and-list .list-top-leaders .name > span {
    display: block;
    height: 2.2em;
    line-height: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.three-leaders-and-list .list-top-leaders .value {
    display: block;
    font-size: 1.43em;
    font-weight: 700;
    margin-top: 15px;
    /*color: #222222;*/
}

/* three-leaders-and-list end */

/* twelve fields gem display tables */
.twelve-fields table.metrics {
    border-collapse: collapse;
    margin: 5px auto 15px auto;
    padding: 0;
    width: 90%;
}
.twelve-fields table.metrics tr th {
    text-align: center;
    padding: 4px 0;
    font-size: 1.2em;
}
.twelve-fields table.metrics tr:nth-child(2) th {
    font-size: 0.9em;
}
.twelve-fields table.metrics td {
    padding: 4px;
    text-align: center;
    font-weight: bold;
}
/* twelve fields gem display tables end */

.list-top-leaders + .list-leaders {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #eaeaea;
}

/* one-leader-and-list */

.one-leader-and-list .top-leader {
    margin: 0;
    padding: 0;
    list-style: none;
}

.one-leader-and-list .countdown {
    margin: 25px 0 15px;
    text-align: center;
}

.one-leader-and-list .list-leaders {
    padding-top: 25px;
    margin-top: 15px;
    border-top: 1px solid #eaeaea;
}

.one-leader-and-list .top-leader > li {
    margin: 12px 12px 0;
}

.one-leader-and-list .top-leader > li:first-child {
    margin-top: 0;
}

.one-leader-and-list .top-leader .leader-item {
    display: table;
    width: 100%;
}

.one-leader-and-list .top-leader .place,
.one-leader-and-list .top-leader .value,
.one-leader-and-list .top-leader .img-wrapper,
.one-leader-and-list .top-leader .name {
    display: table-cell;
    vertical-align: middle;
}

.one-leader-and-list .top-leader .place {
    width: 1%;
    white-space: nowrap;
    font-size: 2em;
    /*color: #222222;*/
    font-weight: 400;
}

.one-leader-and-list .top-leader .place sup {
    font-size: 0.5em;
    top: -10px;
    left: -5px;
}

@media (max-width: 420px) {
    .one-leader-and-list .top-leader .place sup {
        display: none;
    }
}

.one-leader-and-list .top-leader .img-wrapper {
    width: 26%;
    padding-left: 3.4%;
}

@media (min-width: 992px) {
    /*.one-leader-and-list .top-leader .img-wrapper {
        width: 90px;
        padding-left: 10px;
    }*/
}

.one-leader-and-list .top-leader .img-wrapper img {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 50%;
}

.one-leader-and-list .top-leader .name {
    padding: 0 0 0 20px;
    font-size: 1.28em;
    font-weight: 400;
}

.one-leader-and-list .top-leader .value {
    padding: 0 0 0 10px;
    text-align: right;
    font-size: 1.43em;
    white-space: nowrap;
    /*color: #222222;*/
}

.one-leader-and-list .list-leaders .img-wrapper {
    width: 25%;
    padding-left: 8.3%;
}

@media (min-width: 992px) {
    /*.one-leader-and-list .list-leaders .img-wrapper {
        width: 90px;
        padding-left: 30px;
    }*/
}

/**/

.image-dashboard-box .img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

/* Login */
.dashboard-login-page {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.dashboard-login-page .header {
    display: block;
    background-repeat: repeat;
    background-size: contain;
}

.dashboard-login-page .header-inner {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.dashboard-login-page > .page-inner {
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    overflow-x: hidden;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.dashboard-login-page .login-form .img-logo {
    display: block;
    max-width: 175px;
    margin: 0 auto 20px;
}

.dashboard-login-page .page-content {
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.dashboard-login-page .pre-reward-block,
.dashboard-login-page .post-reward-block {
    position: relative;
    min-height: 50px;
}

.dashboard-login-page .pre-reward-block .custom-html-inner,
.dashboard-login-page .post-reward-block .custom-html-inner {
    width: 100%;
}

.login-footer {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.dashboard-login-page .page-content .pre-reward-block,
.dashboard-login-page .page-content .post-reward-block {
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.login-footer .footer-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 15px;
    color: #7c858c;
}

/* Thermometer */
.dashboard-thermometer {
    margin-left: 30px;
    width: 100px;
}
.dashboard-thermometer .glass {
    background: #e5e5e5 !important;
    border-radius: 100px 100px 0 0;
    display: block;
    height: 300px;
    margin: 0 35px 10px;
    padding: 5px;
    position: relative;
    width: 30px;
}
.dashboard-thermometer .amount {
    background: #f34e54;
    border-radius: 100px;
    display: block;
    width: 20px;
    position: absolute;
    bottom: 5px;
}
.dashboard-thermometer strong {
    display: block;
    text-align: center;
}
.dashboard-thermometer .goal {
    font-size: 30px;
}
.dashboard-thermometer .total {
    font-size: 16px;
    position: absolute;
    right: 35px;
}

.dashboard-thermometer .bulb {
    background: #e5e5e5;
    border-radius: 100px;
    display: block;
    height: 60px;
    margin: 0 35px 10px;
    padding: 5px;
    position: relative;
    top: -20px;
    right: 15px;
    width: 60px;
}
.dashboard-thermometer .bulb .red-circle {
    background: #f34e54;
    border-radius: 100px;
    display: block;
    height: 50px;
    width: 50px;
}
.dashboard-thermometer .bulb .filler {
    background: #f34e54;
    border-radius: 100px 100px 0 0;
    display: block;
    height: 30px;
    width: 20px;
    position: relative;
    top: -65px;
    right: -15px;
    z-index: 30;
}

.dashboard-flip-counter-value {
    display: none;
}

/* Mobile */

@media (max-width: 768px) {
    .dashboard-thermometer {
        margin-left: 30px;
        width: 50px;
    }

    .dashboard-thermometer .glass {
        background: #e5e5e5;
        border-radius: 50px 50px 0 0;
        display: block;
        height: 150px;
        margin: 0 18px 5px;
        padding: 2.5px;
        position: relative;
        width: 16px;
    }

    .dashboard-thermometer .amount {
        background: #f34e54;
        border-radius: 100px;
        display: block;
        width: 10px;
        position: absolute;
        bottom: 2px;
    }

    .dashboard-thermometer strong {
        display: block;
        text-align: center;
    }

    .dashboard-thermometer .goal {
        font-size: 30px;
    }

    .dashboard-thermometer .total {
        font-size: 16px;
        position: absolute;
        right: 18px;
    }

    .dashboard-thermometer .bulb {
        background: #e5e5e5;
        border-radius: 100px;
        display: block;
        height: 35px;
        margin: 0 18px 5px;
        padding: 5px;
        position: relative;
        top: -12px;
        right: 9px;
        width: 35px;
    }

    .dashboard-thermometer .bulb .red-circle {
        background: #f34e54;
        border-radius: 100px;
        display: block;
        height: 25px;
        width: 25px;
    }

    .dashboard-thermometer .bulb .filler {
        background: #f34e54;
        border-radius: 100px 100px 0 0;
        display: block;
        height: 15px;
        width: 10px;
        position: relative;
        top: -33px;
        right: -7px;
        z-index: 30;
    }
}

@media print {
    body {
        height: unset;
        overflow-x: visible;
    }

    .dashboard-page {
        overflow-y: auto;
        position: static;
        display: inline;
    }

    .container {
        width: auto;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .dashboard-panel .dashboard-panel-body,
    .dashboard-panel .dashboard .dashboard-col {
        webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
    }

    .dashboard-panel .dashboard .dashboard-col > [class^="dashboard-box"] {
        -webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
        /*page-break-after: always;*/
    }

    .dashboard-panel .dashboard {
        overflow: visible;
    }

    .dashboard-thermometer .glass {
        background: #e5e5e5 !important;
    }
    .dashboard-thermometer .amount {
        background: #f34e54;
    }

    .dashboard-thermometer .bulb {
        background: #e5e5e5 !important;
    }

    .dashboard-thermometer .bulb .red-circle {
        background: #f34e54 !important;
    }

    .dashboard-thermometer .bulb .filler {
        background: #f34e54 !important;
    }

    .flip-timer-item .before {
        display: none;
    }
}

.gridster-item {
    box-shadow: 2px 2px 8px rgba(173, 173, 173, 0.7);
    overflow: hidden;
}
html-template-dashboard-box .html-dashboard-box .dashboard-box-item,
html-template-dashboard-box .html-dashboard-box {
    /*position: absolute;*/
    height: 100%;
    width: 100%;
}

.modal-dialog .dashboard-box-item {
    height: 100%;
}

html-template-dashboard-box .html-dashboard-box .box-body {
    height: 100%;
    width: 100%;
    overflow: auto;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
}

html-template-dashboard-box .html-dashboard-box .box-body.show-more {
    overflow: hidden;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.html-template-modal .modal-dialog .modal-content .show-more-panel,
html-template-dashboard-box .show-more-panel {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;

    height: 45px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.html-template-modal .modal-dialog {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;

    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}

.html-template-modal .modal-dialog .modal-content .show-more-panel .show-more-button,
html-template-dashboard-box .show-more-panel .show-more-button {
    background-color: #4caf50;
    color: white;
    border: none;
    font-size: smaller;
    border-radius: 5px 0px 0px 0px;
    height: 25px;
    right: 0;
    bottom: 0;
}

.html-template-modal .modal-dialog .modal-content .show-more-panel .show-more-button a,
html-template-dashboard-box .show-more-panel .show-more-button a {
    border-radius: 10px;
}

.html-template-modal .modal-dialog .modal-content .show-more-panel .show-more-button a:focus,
.html-template-modal .modal-dialog .modal-content .show-more-panel .show-more-button a:active,
.html-template-modal .modal-dialog .modal-content .show-more-panel .show-more-button a:hover,
.html-template-modal .modal-dialog .modal-content .show-more-panel .show-more-button a,
html-template-dashboard-box .show-more-panel .show-more-button a:focus,
html-template-dashboard-box .show-more-panel .show-more-button a:active,
html-template-dashboard-box .show-more-panel .show-more-button a:hover,
html-template-dashboard-box .show-more-panel .show-more-button a {
    text-decoration: none;
}

.html-template-modal .modal-dialog {
    width: 90%;
    height: 90%;
    overflow: hidden;
}

.html-template-modal .modal-dialog .modal-content {
    height: 100%;
    overflow: hidden;
}

.html-template-modal .modal-dialog .modal-content .html-dashboard-box {
    height: 100%;
    overflow: auto;
}

.html-template-modal .modal-dialog .modal-content {
    min-height: 100%;
}

.logout-button-container {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 999;
}

/*.custom-dashboard .dashboard-box-item {
    position: static !important;
}*/

.weather-box .weather-description {
    margin-bottom: 10px;
}

.weather-box .row {
    margin: 0;
}

.shadow-container,
.shadow {
    height: 100%;
}

.modal-iframe {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.modal-iframe > div,
.modal-iframe iframe {
    width: 100%;
    height: 100%;
}

.hidden {
    visibility: hidden;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 0;
}

.btn-close-modal {
    position: absolute;
    top: 0px;
    right: 0px;
}

.html-template-modal .modal-dialog {
    padding: 5px;
}

.full-screen-modal .modal-dialog {
    margin: 0;
    width: 100%;
    height: 100%;
}

.html-template-modal .modal-dialog .box-body,
.html-template-modal .modal-dialog .html-content,
.html-template-modal .modal-dialog .dashboard-box-item {
    height: 100%;
}

.html-template-modal .modal-dialog .modal-content {
    border-radius: 0;
}

.html-template-modal .modal-dialog .modal-content .modal-iframe iframe {
    border: none;
}

.custom-dashboard .row {
    margin: 0;
}

custom-dashboard .dashboard-container {
    padding-right: 5px;
    padding-left: 5px;
    height: auto;
}

custom-dashboard .dashboard-page {
    overflow-y: auto;
}

.html-content {
    height: 100%;
}

.modal-backdrop {
    z-index: 1000 !important;
}

.custom-dashboard-logo {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-top: 10px;
}

.custom-dashboard-logo-container {
    text-align: center;
    width: 100%;
}

@media print {
    body {
        height: unset;
        overflow-x: visible;
    }

    .dashboard-page {
        overflow-y: auto;
        position: static;
        display: inline;
    }

    .container {
        width: auto;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .dashboard-panel .dashboard-panel-body,
    .dashboard-panel .dashboard .dashboard-col {
        webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
    }

    .dashboard-panel .dashboard .dashboard-col > [class^="dashboard-box"] {
        -webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
        /*page-break-after: always;*/
    }

    .dashboard-panel .dashboard {
        overflow: visible;
    }

    .dashboard-thermometer .glass {
        background: #e5e5e5 !important;
    }
    .dashboard-thermometer .amount {
        background: #f34e54;
    }

    .dashboard-thermometer .bulb {
        background: #e5e5e5 !important;
    }

    .dashboard-thermometer .bulb .red-circle {
        background: #f34e54 !important;
    }

    .dashboard-thermometer .bulb .filler {
        background: #f34e54 !important;
    }

    .flip-timer-item .before {
        display: none;
    }
}

.custom-form-modal input[type="date"] {
    line-height: unset;
}

.custom-form-modal button.btn-warning {
    margin-left: 10px;
}

.dashboard-logout {
    position: fixed;
    right: 30px;
    bottom: 20px;
    z-index: 10000;
}

.dashboard-logout .btn-logout {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 1px 5px 11px #9a9a9a;
    border-width: 0;
    background-color: rgba(255, 255, 255, 0.7);
}

.carousel-inner {
    height: 100%;
}

.carousel-item {
    height: 100%;
    width: 100%;
}

.carousel-item .image {
    width: 100%;
    object-fit: contain;
}

.carousel-indicators  {
    height: auto;
    bottom: auto;
}

.carousel-control {
    z-index: 15;
}

.responsive {
    background-size: 100% auto;
}


.carousel.carousel-fade .carousel-item {
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity ease-out .7s;
}

.carousel.carousel-fade .carousel-item.active {
    position: relative;
    opacity: 1 !important;
    z-index: 10;
}

.cropped-height {
    height: calc(90% - 10px);
}

.indicator {
    border: none !important;
}
.caption-block {
    margin-top: 40px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
