.question-row {
  margin: 30px 0 0;
}

answers {
  display: block;
  margin: 15px 0 0;
}

answers .force-uppercase input,
answers .force-uppercase textarea {
  text-transform: uppercase;
}

.question-panel {
  display: block;
  padding: 0;
  margin: 0 auto;
  float: none;
  overflow: hidden;
  font-size: 18px;
}

.autocomplete {
  background: #eee !important;
}
.acces-question-panel {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.question-panel {
  /*background: #F5F5F5;*/
  background-size: cover;
  min-height: 600px;
}

.question-panel .question-content {
  margin: 0 auto;
  max-width: 800px;
}

.question-panel .question-info {
  display: inline-block;
  font-size: 14px;
}

.question-panel .question-info > span {
  display: inline-block;
  vertical-align: middle;
}

.question-panel form {
  padding: 15px;
}

.question-answer {
  overflow-x: auto;
}

.question-answer .answer-value-wrapper {
  display: block;
  margin: 0 0 0 15px;
  /* padding: 5px 15px; */
  padding-left: 30px;
  background: rgba(255, 255, 255, 0.9);
  /*text-align: center;*/
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  /*color: #23243B;*/
  cursor: pointer;
}

.question-answer .answer-value-wrapper input[type='radio'],
.question-answer .answer-value-wrapper input[type='checkbox'] {
  margin-top: 15px;
}

.question-answer .answer-value-wrapper::before {
  display: table;
  content: ' ';
}

.question-answer .answer-value-wrapper::after {
  clear: both;
  display: table;
  content: ' ';
}

.question-answer .answer-value {
  display: block;
  /*height: 70px;
    white-space: nowrap;*/
  overflow: hidden;
  padding: 10px 0 10px 10px;
}

.question-panel textarea.form-control,
.question-panel input.form-control {
  background: rgba(255, 255, 255, 0.85);
}

.question-answer .answer-value:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.question-answer media-resource p {
  margin: 0;
}

.question-answer .answer-value-wrapper img {
  vertical-align: middle;
  display: inline-block;
  white-space: normal;
  max-height: 70px;
}

.question-answer .multi-image {
  margin-bottom: 20px;
}

.grid-answer-header {
  padding: 5px;
}

.grid-answer-value {
  display: inline-block;
}

.grid-answer-table th {
  text-align: center;
  font-weight: normal;
}

.grid-answer-table tr {
  line-height: 30px;
}

.grid-answer-table tr td {
  text-align: center;
}

.answer-select-image {
  text-align: center;
}

.answer-select-image > .radio {
  display: inline-block;
  vertical-align: top;
}

.answer-select-image > .radio,
.answer-select-image > .radio + .radio {
  margin: 10px;
}

.answer-select-image label.answer-value-wrapper {
  display: inline-block;
}

.answer-select-image .answer-value-wrapper .img {
  display: inline !important;
  width: 128px;
  max-height: 128px;
  margin: 0 auto;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: transform 0.25s;
  transform: scale(1) translate3d(0, 0, 0);
  pointer-events: none;
}

@media (max-width: 380px) {
  .answer-select-image .answer-value-wrapper .img {
    width: 96px;
    max-height: 96px;
  }
}

@media (max-width: 321px) {
  .answer-select-image .answer-value-wrapper .img {
    width: 82px;
    max-height: 82px;
  }
}

.answer-select-image .answer-value-wrapper {
  margin: 0;
  padding: 0;
}

.answer-select-image .answer-value-wrapper .img:hover,
.answer-select-image .answer-value-wrapper input:checked + .img {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  transform: scale(1.05) translate3d(0, 0, 0);
}

question-cim .sound-toggle-btn {
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 100;
}

.answer-address-box .country-region-group select {
  display: none;
}

.answer-address-box .country-region-group.show-country-regions select {
  display: block;
}

.answer-address-box
  .country-region-group.show-country-regions
  input[type='text'] {
  display: none;
}

.question-panel input.form-control.address-optional {
  background: rgba(255, 255, 204, 0.9);
}

.question-panel .question-answer .answer-value-wrapper img {
  max-height: 110px;
  max-width: 110px;
}

.submit-questions {
  margin: 15px 0 15px -15px;
}

#virtualKeyboard {
  -webkit-animation: fadein 100ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 100ms; /* Firefox < 16 */
  -ms-animation: fadein 100ms; /* Internet Explorer */
  -o-animation: fadein 100ms; /* Opera < 12.1 */
  animation: fadein 100ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.multi-image img.highlighted {
  margin-top: 5%;
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  transform: scale(1.05) translate3d(0, 0, 0);
}

.has-error{
  border: 1px solid red;
}

.pac-container{
  top: 34px !important;
  left: 16px !important;
}
