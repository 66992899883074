redemption-search {
    display: block;
    height: 100%;
}

.poscode-wrapper img {
    margin: 0 auto 6px !important;
    border: 5px solid white;
    border-radius: 6px;
}

.reward-code-box {
    background-image: url('/images/dock-light.jpg');
    /* background-attachment: fixed !important; */
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 100%;
    padding-bottom: 20px;
}

.reward-code-box .mode-links .btn {
    min-width: 100px;
}

.reward-code-box .mode-links .btn-default {
    background-color: #e6e6e6;
}

.reward-code-box .reward-data {
    border: 2px solid #EEE;
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 15px;
    background: #EEE;
}

.reward-code-box .image-stamp-wrapper img {
    max-width: 200px;
}

.reward-code-box > .row {
    padding-top: 30px;
}

.reward-code-box .panel-heading .client-logo-img {
    margin: 0 auto;
}

.reward-code-box .panel {
    background: transparent;
    border: 0;
    box-shadow: 0 0 0 rgb(0,0,0);
}

.reward-code-box .panel-heading {
    background: rgba(245,245,245,0);
    border: 0;
}

.reward-code-box .panel-body {
    background: rgba(255,255,255,0);
    border: 0;
    box-shadow: 0;
}

.reward-code-box .field-tip {
    margin: 0 0 5px;
    padding: 6px 15px;
}

.reward-code-box .panel-heading {
    overflow: hidden;
}

.reward-code-box .panel-heading .title {
    margin: 4px 0 0;
    text-align: center;
}

.reward-code-box .form-group.submit-button {
    text-align: center;
}

.reward-code-box .form-group button {
    width: 100%;
}

.reward-code-box .reward-name {
    font-weight: 600;
    font-size: 16pt;
    margin-bottom: 5px;
}

.reward-code-box .reward-expires,
.reward-code-box .reward-startDate {
    font-weight: 600;
    font-size: 16pt;
    margin: 5px;
}

.reward-code-box .reward-qrcode {
    font-size: 16pt;
}

.reward-code-box .reward-redemption-note {
    margin-top: 10px;
    color: #337ab7;
}

.reward-code-box .reward-terms {
    max-width: 400px;
    font-size: 6pt;
    margin: 0 auto;
    text-align: justify;
    cursor: pointer;
}

.reward-code-box .reward-form {
    padding: 0;
}

.reward-code-box .form-group .alert {
    margin-bottom: 0;
}

.btn-default.goback {
    background: #e6e6e6;
}

.countdown-reward-row {
    display: block;
    margin: 5px 0 0;
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.countdown-reward-row .countdown-reward-header {
    margin: 5px 0;
    font-weight: 700;
    font-size: 16px;
}

.countdown-reward {
    display: block;
    max-width: 80%;
    margin: 0 auto;
}

.countdown-reward .img {
    display: block;
    max-width: 100%;
    max-height: 160px;
    margin: 0 auto;
}

.countdown-reward .countdown-reward-name {
    display: block;
    margin: 6px 0 0;
    font-weight: 700;
    text-align: center;
}

.countdown-timer {
    display: inline-block;
    font-size: 1.1em;
    font-weight: 700;
}

.countdown-timer > span {
    display: inline-block;
    vertical-align: middle;
}

.reward-code-box .survey-responses-wrapper,
.reward-code-box .campaign-name-wrapper {
    background: #FFFFFF;
}

.reward-code-box .survey-responses-wrapper .table,
.reward-code-box .campaign-name-wrapper .table {
    margin: 0;
}

.reward-code-box .survey-responses-wrapper .table {
    table-layout: fixed;
}

.reward-code-box .survey-responses-wrapper .table .answer {
    word-wrap: break-word;
}

.reward-code-box .reward-print-spacing {
    margin-top: 10px;
}

.reward-code-box .reward-print-signature {
    margin-top: 20px;
    margin-bottom: 20px;
}

.reward-code-box .reward-terms-print {

}

@media screen and (max-width: 480px) {
    .reward-code-box .pos-canvas > canvas,
    .reward-code-box .pos-canvas > img {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 768px) {
    .reward-code-box .panel-heading .client-logo-img {
        max-width: 44%;
        margin: 0 auto;
        float: none;
    }
}

@media print {
    @page {
        margin: 3mm;
        padding: 0;
        border: 0;
    }
    .reward-code-box .panel-body {
        margin: 0;
        padding: 0;
        border: 0;
    }
    .reward-code-box div {
        margin: 0;
        padding: 0;
        border: 0;
    }
    .reward-code-box .reward-data {
        margin: 0;
        padding: 0;
        border: 0;
    }

    .reward-code-box .reward-terms-print {
        
    }

}

.reward-redemption-note {
    max-height: 100px;
    overflow-y: auto;
    padding-right: 10px;
    white-space: pre-line;
}