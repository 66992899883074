@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikSuper.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikSuperItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikBlack.ttf')
    format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikBlackItalic.ttf')
    format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikBold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikBoldItalic.ttf')
    format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikSemibold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikSemiboldItalic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikMedium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikMediumItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikRegular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikRegularItalic.ttf')
    format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikLight.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikLightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikExtralight.ttf')
    format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikExtralightItalic.ttf')
    format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikThin.ttf')
    format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://devocdata.blob.core.windows.net/media/D2936FE29960/commonfonts/last-kiosk/GraphikThinItalic.ttf')
    format('truetype');
  font-weight: 100;
  font-style: italic;
}

.body-touchpoint .touchpoint-questions-wrapper,
.body-touchpoint question-cim,
.body-touchpoint .touchpoint-cim-questions-wrapper {
  height: 100%;
}

.touchpoint-question-element,
.touchpoint-cim-questions-wrapper__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.touchpoint-cim-questions-wrapper__form {
  height: 100%;
  justify-content: space-between;
  padding: 0 120px 120px;
}

@media (max-width: 1200px) {
  .touchpoint-cim-questions-wrapper__form {
    padding: 0 60px 60px;
  }
}

.touchpoint-question-element__subtitle {
  font-family: Graphik;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.1;
  color: #293b55;
  text-transform: uppercase;
  margin: 120px 0 0;
}

@media (max-width: 1200px) {
  .touchpoint-question-element__subtitle {
    font-size: 21px;
    margin: 60px 0 0;
  }
}

.touchpoint-question-element__title {
  font-family: Graphik;
  font-size: 64px;
  font-weight: 600;
  line-height: 96px;
  color: #293b55;
  text-align: center;
  margin: 100px 0 120px;
}

@media (max-width: 1200px) {
  .touchpoint-question-element__title {
    font-size: 32px;
    line-height: 48px;
    margin: 50px 0 60px;
  }
}

.touchpoint-checkbox-wrapper,
.touchpoint-radio-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 80vw;
}

.touchpoint-checkbox-wrapper__column,
.touchpoint-radio-wrapper__column {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 80px;
}

@media (max-width: 1200px) {
  .touchpoint-checkbox-wrapper__column,
  .touchpoint-radio-wrapper__column {
    gap: 40px;
  }
}

.touchpoint-checkbox-wrapper__item,
.touchpoint-radio-wrapper__item {
  display: flex;
  align-items: center;
}

.touchpoint-checkbox-wrapper__checkbox .touchpoint-checkbox-wrapper__input,
.touchpoint-radio-wrapper__radio .touchpoint-radio-wrapper__input {
  display: none;
}

.touchpoint-checkbox-wrapper__checkbox,
.touchpoint-radio-wrapper__radio {
  display: flex;
  align-items: center;
  gap: 30px;
}

@media (max-width: 1200px) {
  .touchpoint-checkbox-wrapper__checkbox,
  .touchpoint-radio-wrapper__radio {
    gap: 15px;
  }
}

.touchpoint-checkbox-wrapper__box {
  position: relative;
  display: block;
  border: 4px solid #dbdddf;
  background: #f0f0f0;
  width: 66px;
  height: 66px;
  border-radius: 18px;
}

@media (max-width: 1200px) {
  .touchpoint-checkbox-wrapper__box {
    border-width: 2px;
    width: 33px;
    height: 33px;
    border-radius: 9px;
  }
}

.touchpoint-radio-wrapper__box {
  position: relative;
  display: block;
  border: 4px solid #dbdddf;
  background: #f0f0f0;
  width: 66px;
  height: 66px;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .touchpoint-radio-wrapper__box {
    border-width: 2px;
    width: 33px;
    height: 33px;
  }
}

.touchpoint-checkbox-wrapper__box:before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-57%, -85%, 0) rotate(-45deg);
  width: 36px;
  height: 24px;
  border: 4px solid white;
  border-top-color: transparent;
  border-right-color: transparent;
  transition: 0.2s ease-in-out;
  opacity: 0;
}

@media (max-width: 1200px) {
  .touchpoint-checkbox-wrapper__box:before {
    border-width: 2px;
    width: 18px;
    height: 12px;
  }
}

.touchpoint-radio-wrapper__box:before {
  content: '';
  display: block;
  position: absolute;
  left: 4px;
  top: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  box-shadow: inset 0 0 0 4px #03a19f;
  border: 4px solid white;
  background: #00aea9;
  transition: 0.2s ease-in-out;
  opacity: 0;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .touchpoint-radio-wrapper__box:before {
    border-width: 2px;
    left: 2px;
    top: 2px;
    box-shadow: inset 0 0 0 2px #03a19f;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }
}

.touchpoint-checkbox-wrapper__checkbox
  .touchpoint-checkbox-wrapper__input[type='checkbox']:checked
  + .touchpoint-checkbox-wrapper__box {
  border-color: #03a19f;
  background: #00aea9;
}

.touchpoint-checkbox-wrapper__checkbox
  .touchpoint-checkbox-wrapper__input[type='checkbox']:checked
  + .touchpoint-checkbox-wrapper__box:before {
  opacity: 1;
}

.touchpoint-radio-wrapper__radio
  .touchpoint-radio-wrapper__input[type='radio']:checked
  + .touchpoint-radio-wrapper__box {
  border-color: #03a19f;
}

.touchpoint-radio-wrapper__radio
  .touchpoint-radio-wrapper__input[type='radio']:checked
  + .touchpoint-radio-wrapper__box:before {
  opacity: 1;
}

.touchpoint-checkbox-wrapper__span,
.touchpoint-radio-wrapper__span {
  font-family: Graphik;
  font-size: 64px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .touchpoint-checkbox-wrapper__span,
  .touchpoint-radio-wrapper__span {
    font-size: 32px;
  }
}

.touchpoint-cim-questions-wrapper__form .btn-fancy {
  display: flex;
  gap: 30px;
  background: #00aea9;
  border: none;
  border-radius: 18px;
  font-family: Graphik;
  font-size: 56px;
  font-weight: 500;
  line-height: 68px;
  padding: 20px 40px;
  margin: 150px 0 0;
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.6;
  transition: 0.2s ease-in-out;
}

.touchpoint-cim-questions-wrapper__form.touchpoint-cim-questions-wrapper__form--valid
  .btn-fancy {
  filter: grayscale(0);
  pointer-events: all;
  opacity: 1;
}

@media (max-width: 1200px) {
  .touchpoint-cim-questions-wrapper__form .btn-fancy {
    gap: 15px;
    border-radius: 9px;
    font-size: 28px;
    line-height: 34px;
    padding: 10px 20px;
    margin: 75px 0 0;
  }
}

.touchpoint-yes-no-wrapper {
  display: flex;
  justify-content: center;
  gap: 50px;
}

@media (max-width: 1200px) {
  .touchpoint-yes-no-wrapper {
    gap: 25px;
  }
}

.touchpoint-yes-no__item {
  position: relative;
}

.touchpoint-yes-no__item .touchpoint-yes-no__input {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
}

.touchpoint-yes-no__span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 510px;
  height: 190px;
  border: 4px solid #bfc4cc;
  background: #f8f9fa;
  font-family: Graphik;
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  color: #293b55;
  border-radius: 20px;
}

@media (max-width: 1200px) {
  .touchpoint-yes-no__span {
    width: 255px;
    height: 95px;
    border-width: 2px;
    font-size: 24px;
    border-radius: 10px;
  }
}

.touchpoint-yes-no__input:checked + .touchpoint-yes-no__span {
  border-color: #00aea9;
  background: #00aea9;
  color: white;
}

.touchpoint-rating-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 54px;
  margin: 20px 0 120px;
}

@media (max-width: 1200px) {
  .touchpoint-rating-wrapper {
    gap: 27px;
    margin: 10px 0 60px;
  }
}

.touchpoint-rating-wrapper__item {
  position: relative;
}

.touchpoint-rating-wrapper__input {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
}

.touchpoint-rating-wrapper__start-checked,
.touchpoint-rating-wrapper__start-unchecked {
  width: 286px;
}
@media (max-width: 1200px) {
  .touchpoint-rating-wrapper__start-checked,
  .touchpoint-rating-wrapper__start-unchecked {
    width: 143px;
  }
}

.touchpoint-rating-wrapper__item:not(.touchpoint-rating-wrapper__item--active)
  .touchpoint-rating-wrapper__start-checked {
  display: none;
}

.touchpoint-rating-wrapper__item.touchpoint-rating-wrapper__item--active
  .touchpoint-rating-wrapper__start-unchecked {
  display: none;
}
