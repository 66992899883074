html {
  height: 100%;
  overflow: hidden;
}

body {
  background: #fdfdfd;
  height: 100%;
  overflow-x: hidden;
}

#main-container {
  height: 100%;
}

#main-container.with-keyboard {
  display: flex;
  flex-direction: column;
}

#main-container.with-keyboard > * {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

#main-container.with-keyboard * {
  flex-grow: 1;
}

.view-animation[ui-view].ng-enter,
.view-animation[ui-view].ng-leave {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.cim-transition[question-cim].ng-enter,
.cim-transition[question-cim].ng-leave {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.view-animation[game-preview].ng-enter {
  z-index: 100;
}

.view-animation[game-preview].ng-leave {
  z-index: 99;
}

.cim-transition[game-preview].ng-enter,
.cim-transition[game-preview].ng-leave {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.cim-transition[game-preview].ng-enter {
  z-index: 100;
}

.cim-transition[game-preview].ng-leave {
  z-index: 99;
}

pre {
  display: block;
  padding: 0;
  margin: 0 0 10px;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  word-break: normal;
  word-wrap: normal;
  white-space: pre-wrap;
  background-color: #fff;
  border: 0;
  font-family: inherit;
}

input[type='radio']:checked + span {
  font-weight: bold;
}

.img {
  max-width: 100%;
}

.btn.btn-min-width {
  min-width: 100px;
}

.page-loading-row {
  margin: 20px 0;
  text-align: center;
}

.panel-transparent {
  background: transparent;
  border: 0;
  box-shadow: none;
  margin-top: 20px;
}

.panel-transparent .panel-heading {
  border: 0;
}

logout {
  display: inline-block;
}

logout .btn {
  min-width: 120px;
}

logout.fixed {
  position: fixed;
  right: 17px;
  bottom: 10px;
}

@media screen and (max-width: 768px) {
  logout.fixed {
    position: static;
    display: block;
    text-align: center;
    margin: 50px 0 15px;
  }
}

.sound-toggle-btn {
  position: relative;
  display: inline-block;
  width: 88px;
  height: 80px;
  background: url('/images/mute-btn-sprite.png') no-repeat 0 0;
  background-size: cover;
}

howler-toggle-button .sound-toggle-btn {
  /*position: absolute;
    top: 5px;
    left: 10px;
    z-index: 100;*/
}

.sound-toggle-btn.muted {
  background-position-y: -80px;
}

@media screen and (max-width: 768px) {
  .sound-toggle-btn {
    /*width: 44px;
        height: 40px;*/
  }

  .sound-toggle-btn.muted {
    background-position-y: -40px;
  }
}

div.campaign-end {
  height: 25px;
  width: 25px;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 20000;
}

.campaign-end span {
  text-shadow: 1px 1px 2px #000;
  color: white;
  opacity: 0.4;
  position: fixed;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

ac-interaction .campaign-end {
  display: none;
}

.preview-mode-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 100;
  pointer-events: none;
}

.preview-mode-warning {
  font-size: 1em;
  /* color: rgba(255, 255,255,.5);*/
  /* text-shadow: 1px 1px 2px #333; */
  text-shadow: 1px 1px 2px #000;
  color: white;
  opacity: 0.2;
  position: fixed;
  z-index: 200;
}

.preview-mode-settings {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -32px;
  z-index: 9999;
}

@media (min-width: 768px) {
  .preview-mode-settings {
    top: 0;
    left: auto;
    margin-left: 0;
    right: 140px;
  }
}

.preview-mode-warning.upper-left {
  top: 10px;
  left: 20px;
}

.preview-mode-warning.upper-right {
  top: 10px;
  right: 20px;
}

.preview-mode-warning.lower-left {
  bottom: 10px;
  left: 20px;
}

.preview-mode-warning.lower-right {
  bottom: 10px;
  right: 20px;
}

.page-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.campaign-over-message {
  position: relative;
  padding: 20px 20%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
}

@media (max-width: 768px) {
  .campaign-over-message {
    padding: 10px;
  }
}

#main-container .page-loader {
  z-index: 1;
}

.panel-heading:after {
  content: '';
  display: table;
  clear: both;
}

.panel-heading .client-logo-img {
  display: block;
  max-height: 100px;
  max-width: 100%;
  margin: 0 auto 0;
}

@media screen and (max-width: 768px) {
  .panel-heading .client-logo-img {
    max-height: 50px;
  }

  .panel-heading h2,
  .panel-heading .title {
    font-size: 16px;
  }
}

.panel-heading h2,
.panel-heading .title {
  margin: 0;
  text-align: center;
  word-wrap: break-word;
}

.panel-body .img-responsive {
  margin-left: auto;
  margin-right: auto;
}

.terms-conditions {
  display: block;
  margin: 6px 30px;
  font-size: 8pt;
}

.reward-terms-conditions {
  text-align: right;
  margin: 0;
}

.reward-terms-conditions {
  display: flex;
}

.reward-terms-conditions span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
  width: 100%;
  text-align: left;
}

.reward-terms-conditions a {
  display: inline-block;
  float: right;
  margin-left: 10px;
  width: 70px;
}

@media screen and (min-width: 768px) {
  .panel-heading .client-logo-img {
    float: right;
    max-width: 44%;
    margin-left: 10px;
  }

  .panel-heading h2,
  .panel-heading .title {
    text-align: left;
    margin: 5px 0 0;
  }
}

.login-form-back,
.login-form-group {
  background-image: url('/images/dock.jpg');
  background-attachment: fixed !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: auto !important;
  min-height: 100%;
  height: 100%;
  padding-bottom: 90px;
}

.auth-title {
  font-weight: 600;
  margin: 0 0 20px;
}

.lang-label {
  margin: 5px 0;
}

.lang-row > div:nth-child(1) {
  padding-right: 5px;
}

.lang-row > div:nth-child(2) {
  padding-left: 5px;
}

.login-form,
.forgot-password-form,
.reset-password-form {
  margin: 0px auto;
  padding-top: 50px;
  max-width: 300px;
}

.reset-password-form > .form-group {
  margin: 15px 10px;
}

.password-reset-form {
  margin: 0px auto;
  padding: 0px 15px;
  max-width: 485px;
}

.reset-password {
  padding-top: 150px;
}

.reset-pin-form {
  margin: 10px auto;
  max-width: 500px;
}

.login-form input {
  opacity: 0.75;
}

.login-form a {
  color: black;
}

.login-form button {
  width: 100%;
}

.client-logo {
  margin: 15px 0 30px;
  display: block;
  text-align: center;
}

.client-logo img {
  display: inline-block;
  max-height: 405px;
  max-width: 405px;
}

.client-logo.small-logo img {
  max-height: 189px;
  max-width: 189px;
}

.lobby-logo {
  text-align: center;
}

.lobby-item {
  float: none;
  vertical-align: top;
  border: none;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .lobby-item {
    display: inline-block;
  }
}

.lobby-image {
  border: 0px solid blue;
  position: relative;
  min-height: 100px;
}

.row.campaign-lobby > .panel {
  margin: 20px 40px;
}

.campaign-lobby .panel {
  margin-top: 10px;
}

.lobby-item .well {
  /* height: 325px;*/
  border-radius: 0;
}

.lobby-image {
  overflow: hidden;
}

.lobby-image img {
  width: 100%;
  height: auto;
}

.lobby-image p {
  padding: 10px;
}

.lobby-overlay-close {
  padding-right: 10px;
  padding-top: 10px;
}

.lobby-image .campaign-title {
  position: absolute;
  bottom: 10px;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
}

.lobby-image .campaign-info h4 {
  padding: 5px;
  font-size: 14pt;
  margin: 0;
  margin-left: 5px;
}

.lobby-image .campaign-info p {
  padding-top: 0;
}

.lobby-actions {
  margin-top: 10px;
}

.lobby-actions .btn {
  margin-left: 5px;
}

.lobby-actions .btn:first-child {
  margin-left: 0;
}

/*.terms-link {
    font-weight: bold;
    color: #333333;
    text-decoration: underline;
}

.terms-link:hover {
    color: #000;
    text-decoration: none;
}*/

.campaign-info,
.campaign-description,
.campaign-tc {
  position: absolute;
  top: 0;
  height: 70%;
  background: rgba(255, 255, 255, 0.9);
  width: 95%;
  margin-left: 10px;
  margin-top: 10px;
  display: block;
  overflow: auto;
}

.campaign-info.ng-hide-add.ng-hide-add-active,
.campaign-info.ng-hide-remove.ng-hide-remove-active {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}

.campaign-info.ng-hide {
  top: 312px;
}

.campaign-info.ng-hide-add.ng-hide-add-active {
  top: -312px;
}

.campaign-rewards h4,
.campaign-description h4,
.campaign-tc h4 {
  margin-left: 10px;
}

.question-heading {
  padding-left: 15px;
  margin-bottom: 15px;
}

.question-heading h2 {
  margin-top: 10px;
}

.panel-body.question-panel {
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}

button.questions-next {
  margin-right: 15px;
}

iframe.embedded {
  width: 100%;
  height: 375px;
  overflow: auto;
  border: 1px solid #ccc;
}

.image-background-shading {
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border: 0px solid #eee;
  border-radius: 8px;
  min-width: 148px;
  min-height: 148px;
}

.reward-thumbnail-description {
  position: absolute;
  top: 0;
  left: 0px;
  font-weight: bold;
  font-size: 100%;
  color: white;
  text-align: left;
  text-shadow: 1px 1px 1px #000;
  border: 0px solid red;
  width: 100px;
  padding: 5px;
  line-height: 1.25;
}

.reward-thumbnail-odds {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-weight: bold;
  font-size: 87.5%;
  color: white;
  text-shadow: 1px 1px 1px #333;
}

.notawinner {
  transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  /*visibility: hidden; */
  opacity: 0;
}

button.small-spin {
  margin-top: 30%;
}

.collect-reward-button {
  margin-top: -5px;
}

a {
  cursor: pointer;
}

/*
position: relative;
  top: 50%;
  transform: translateY(-50%);
  */

/* Appear */

.animate-enter,
.animate-leave {
  -webkit-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -moz-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -ms-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -o-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.animate-leave.animate-leave-active,
.animate-enter {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.animate-enter.animate-enter-active,
.animate-leave {
  opacity: 1;
  width: 150px;
  height: 30px;
}

canvas#canvas {
  max-width: 110px;
  max-height: 100px;
}

.mode-links {
  position: relative;
  margin: 15px 0 15px;
  text-align: center;
  z-index: 2;
}

.mode-links a {
  display: inline-block;
  margin: 0 6px;
  color: #333333;
}

.screensaver {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #eee;
  z-index: 9999;
}

.screensaver .carousel {
  position: absolute;
  width: 100%;
  height: 100%;
}

.screensaver .carousel-control,
.screensaver .carousel-indicators {
  display: none;
}

.screensaver .carousel-inner,
.screensaver .carousel .item {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  /*font-size: 0;*/
}

.screensaver .carousel .slide-img,
.screensaver .carousel video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.screensaver .carousel .video-wrapper {
  position: static;
}

.screensaver .carousel .alert {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 480px) {
  /* Styles */
  canvas#canvas {
    max-width: none;
    max-height: none;
    width: 90%;
  }
}

.image-stamp-wrapper {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.image-stamp-wrapper .img {
  display: block;
  margin: auto;
  max-width: 100%;
  height: auto;
}

.reward-holder .message-wrapper,
.image-stamp-wrapper .message-wrapper {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -33px;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  z-index: 1;
  pointer-events: none;
}

.reward-holder .message-wrapper .message {
  transform: rotate(-45deg) scale(0.5);
  transform-origin: 50% 50%;
}

.reward-holder .message-wrapper .message,
.image-stamp-wrapper .message-wrapper .message {
  display: block;
  font-family: Arial, sans-serif;
  font-size: 40px;
  line-height: 45px;
  color: #c00;
  background: #fff;
  border: solid 4px #c00;
  padding: 5px;
  border-radius: 5px;
  opacity: 0.8;
  -webkit-text-shadow: 0 0 2px #c00;
  text-shadow: 0 0 2px #c00;
  box-shadow: 0 0 2px #c00;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1px;
  z-index: 1;
}

.reward-holder .message-wrapper .message.cancelled,
.image-stamp-wrapper .message-wrapper .message.cancelled {
  font-size: 32px;
}

.video-wrapper {
  position: relative;
  text-align: center;
}

.video-wrapper video {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.media-resource {
  display: block;
  margin: 0 auto;
  max-width: 1240px;
}

.media-resource .img-responsive {
  margin: 0 auto;
}

.poscode-wrapper canvas,
.poscode-wrapper img {
  max-width: 100%;
}

/* Rewards list to swap or choose from bundle */

.selectable-rewards-list .rewards-list {
  margin: 0 0 30px;
}

.selectable-rewards-list .radio-inline {
  display: block;
  padding: 0;
  margin: 0 15px;
}

.selectable-rewards-list .radio-inline + .radio-inline {
  margin: 0 0 0 10px;
}

.selectable-rewards-list .slick-track {
  margin-left: auto;
  margin-right: auto;
}

.selectable-rewards-list .slick-slide:focus {
  outline: none;
}

.selectable-rewards-list .reward-item {
  display: block;
  margin: 0 10px;
  text-align: center;
  padding: 4px;
  vertical-align: middle;
  white-space: normal;
  cursor: pointer;
}

.selectable-rewards-list .reward-item .reward-front,
.selectable-rewards-list .reward-item .reward-back {
  border-radius: 10px;
  border: 2px solid #ddd;
}

.selectable-rewards-list .slick-current .reward-item {
  /*border-color: #e8bd42;*/
}

.selectable-rewards-list .slick-current .reward-item .reward-front,
.selectable-rewards-list .slick-current .reward-item .reward-back {
  border-color: #e8bd42;
}

@media (min-width: 680px) {
  .selectable-rewards-list .reward-item {
    opacity: 1;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: scale 0.25s ease-out;
    -moz-transition: scale 0.25s ease-out;
    transition: transform 0.25s ease-out;
    height: 210px;
  }

  .selectable-rewards-list .slick-current .reward-item {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.selectable-rewards-list .reward-item .reward-img {
  display: inline-block;
  max-width: 100%;
  height: 120px;
  margin: 0 0 5px;
  border-radius: 10px;
}

.selectable-rewards-list .reward-item .reward-name {
  display: block;
  margin: 0 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selectable-rewards-list .slick-next {
  width: 50px;
  height: 70px;
  background-position: center;
  background-image: url('../images/rightarrow.png');
  background-repeat: no-repeat;
  background-color: #a6a7ac;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transition: background 0.2s ease-out;
  -moz-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
  z-index: 2;
  border-radius: 18px;
  opacity: 0.75;
}

.selectable-rewards-list .slick-prev {
  width: 50px;
  height: 70px;
  background-position: center;
  background-image: url('../images/leftarrow.png');
  background-repeat: no-repeat;
  background-color: #a6a7ac;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transition: background 0.2s ease-out;
  -moz-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
  z-index: 2;
  border-radius: 18px;
  opacity: 0.75;
}

.selectable-rewards-list .slick-next:before,
.selectable-rewards-list .slick-prev:before {
  display: none;
}

.selectable-rewards-list .slick-next:hover,
.selectable-rewards-list .slick-prev:hover {
  background-color: #575d79;
}

/* Selectable Rewards List Mobile View */

.selectable-rewards-list .reward-list-view {
  padding-left: 0;
  list-style-type: none;
  display: block;
}

.selectable-rewards-list .reward-list-view li {
  min-height: 70px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.selectable-rewards-list .reward-list-view .as-table {
  display: table;
}

.selectable-rewards-list .reward-list-view .as-col {
  display: table-cell;
  vertical-align: middle;
}

.selectable-rewards-list .reward-list-view li:nth-child(2) {
  border-top: 1px solid #ccc;
}

.selectable-rewards-list .reward-list-view .selected {
  background: #ffffea;
}

.selectable-rewards-list .reward-list-view .choose-instructions {
  height: auto;
  padding: 0 30px;
  background: #ffffcc;
}

.selectable-rewards-list .reward-list-view .choose-instructions > div {
  display: table;
  width: 100%;
  height: 70px;
}

.selectable-rewards-list .reward-list-view .choose-instructions > div > span {
  display: table-cell;
  vertical-align: middle;
}

.swap-reward-box .reward-list-view .choose-instructions {
  display: none;
}

.selectable-rewards-list .reward-list-view .list-view-img,
.selectable-rewards-list .reward-list-view .list-view-text {
  float: left;
  display: table;
  height: 70px;
  vertical-align: middle;
}

.selectable-rewards-list .reward-list-view .list-view-img {
  width: 15%;
  margin-left: 10px;
}

.selectable-rewards-list .reward-list-view .list-view-text {
  width: 75%;
  padding: 5px 10px;
}

.selectable-rewards-list .reward-list-view .list-view-img > div,
.selectable-rewards-list .reward-list-view .list-view-text > div {
  display: table-cell;
  vertical-align: middle;
}

.selectable-rewards-list .reward-list-view .list-view-img img {
  max-width: 50px;
}

.selectable-rewards-list .reward-list-view .list-view-text .name {
  font-weight: bold;
  font-size: 112%;
}

.selectable-rewards-list .reward-list-view .list-view-text .btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 0;
}

.selectable-rewards-list .reward-list-view .list-view-text > .expires {
  margin-top: 5px;
  font-size: 87.5%;
}

/* Selectable Rewards List Mobile View End */

videogular vg-time-display,
[videogular] vg-time-display {
  font-size: 14px;
  width: 55px;
}

.video-container {
  display: block;
}

.video-container .alert {
  position: relative;
}

.video-container vg-media,
.video-container oc-custom-media {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container oc-custom-media video,
.video-container oc-custom-media iframe {
  width: 100%;
  height: 100%;
}

trivia-game .video-container oc-custom-media {
  display: block;
  text-align: center;
}

trivia-game .video-container oc-custom-media oc-custom-media-youtube iframe {
  width: unset;
  height: unset;
}

.campaign-mode-ac-wrapper .panel-transparent .panel-heading .client-logo-img {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

reward .reward-info .countdown-timer,
mobile-reward .reward-info .countdown-timer {
  font-size: 1em;
}

reward .countdown-timer .counter,
mobile-reward .countdown-timer .counter {
  font-weight: 400;
}

/* FLIP LIST */

.flip-timer {
  display: inline-block;
}

.flip-timer .flip-timer-item {
  display: inline-block;
}

.flip-timer .flip-item-label {
  margin: 10px 0 0;
  font-size: 0.785em;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.flip {
  position: relative;
  list-style: none;
  margin: 0 4px;
  width: 54px;
  height: 54px;
  font-size: 2em;
  font-weight: 400;
  line-height: 54px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.flip li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.flip li:first-child {
  z-index: 2;
}

.flip li a {
  display: block;
  height: 100%;
  perspective: 200px;
  cursor: pointer;
  text-decoration: none;
  cursor: default;
}

.flip li a:hover {
  text-decoration: none;
}

.flip li div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.flip li div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.flip li div.up {
  transform-origin: 50% 100%;
  top: 0;
}

.flip li div.up:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.4);
}

.flip li div.down {
  transform-origin: 50% 0%;
  bottom: 0;
}

.flip li div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: #fff;
  text-shadow: 0 1px 2px #a1a1a1;
  text-align: center;
  background-color: #a1a1a1;
  border-radius: 6px;
}

.flip li .up .inn {
  top: 0;
}

.flip li .down .inn {
  bottom: 0;
}

/* PLAY */

.flip.play li.before {
  z-index: 3;
}

.flip.play li.active {
  animation: flipListAsd 0.5s 0.5s linear both;
  z-index: 2;
}

@keyframes flipListAsd {
  0% {
    z-index: 2;
  }
  5% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}

.flip.play li.active .down {
  z-index: 2;
  animation: flipListTurn 0.5s 0.5s linear both;
}

@keyframes flipListTurn {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.flip.play li.before .up {
  z-index: 2;
  animation: flipListTurn2 0.5s linear both;
}

@keyframes flipListTurn2 {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-90deg);
  }
}

/* SHADOW */

.flip.play li.before .up .shadow {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.1)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  animation: flipListShow 0.5s linear both;
}

.flip.play li.active .up .shadow {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.1)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  animation: flipListHide 0.5s 0.3s linear both;
}

/*DOWN*/

.flip.play li.before .down .shadow {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(100%, rgba(0, 0, 0, 0.1))
  );
  background: linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  animation: flipListShow 0.5s linear both;
}

.flip.play li.active .down .shadow {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(100%, rgba(0, 0, 0, 0.1))
  );
  background: linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  animation: flipListHide 0.5s 0.3s linear both;
}

@keyframes flipListShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flipListHide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Center modal window */

.modal-custom {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.modal-vertical-align .modal-dialog {
  height: 100%;
  margin: 0 auto;
  padding: 10px;
  white-space: nowrap;
}

.modal-vertical-align .modal-dialog:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-vertical-align .modal-content {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  white-space: normal;
}

@media (max-width: 992px) {
  .modal-vertical-align-tablet .modal-dialog {
    height: 100%;
    margin: 0 auto;
    padding: 10px;
    white-space: nowrap;
    text-align: center;
  }

  .modal-vertical-align-tablet .modal-dialog:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-vertical-align-tablet .modal-content {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    white-space: normal;
  }
}

@media (max-width: 768px) {
  .modal-vertical-align-mobile .modal-dialog {
    height: 100%;
    margin: 0 auto;
    padding: 10px;
    white-space: nowrap;
  }

  .modal-vertical-align-mobile .modal-dialog:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-vertical-align-mobile .modal-content {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    white-space: normal;
  }
}

/* Center modal window End */

.no-pointer-events {
  pointer-events: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* News Ticker */

.news-ticker-scroll-item {
  font-size: 24px;
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.news-ticker-scroll {
  height: 39px;
  line-height: 35px;
  margin: 1em -1em;
  overflow: hidden;
  position: relative;
  display: none;
  border-radius: 6px;
}

.news-ticker-scroll .news-ticker-scroll-item {
  height: 38px;
  display: inline-block;
  position: relative;
  overflow-y: hidden;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0);
  color: #212529;
}

.news-ticker-scroll .news-ticker-scroll-item > div {
  position: relative;
}

.news-ticker-scroll .news-ticker-scroll-item span {
  position: relative;
  padding: 5px;
}

news-ticker {
  z-index: 99999;
}

news-ticker
  + .dashboard-panel-heading
  + .dashboard-panel-body
  > .dashboard-oneBox
  > .dashboard-col
  > .dashboard-box {
  position: unset;
}

.news-ticker-item-full-container {
  position: absolute;
  border-radius: 6px;
  height: max-content;
  margin-left: 60px;
  text-align: center;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  font-weight: 400;
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.15);
  color: #212529;
  left: 0;
  z-index: 99999;
}

.news-ticker-item-full-container .news-ticker-item-full-arrow {
  display: none;
}

.news-ticker-item-full-container
  .news-ticker-item-full-arrow.news-ticker-bottom-arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}

.news-ticker-item-full-container
  .news-ticker-item-full-arrow.news-ticker-bottom-arrow:after {
  position: absolute;
  display: block;
  left: 50%;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
  bottom: -10px;
  margin-left: -10px;
  content: ' ';
  border-top-color: #f7f7f7;
  border-bottom-width: 0;
}

.news-ticker-item-full-container
  .news-ticker-item-full-arrow.news-ticker-top-arrow {
  border-width: 11px;
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.news-ticker-item-full-container
  .news-ticker-item-full-arrow.news-ticker-top-arrow:after {
  position: absolute;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  content: '';
  border-width: 10px;
  top: -9px;
  margin-left: -10px;
  content: ' ';
  border-top-width: 0;
  border-bottom-color: #f7f7f7;
}

.apple-wallet-block {
  text-align: center;
  margin: 12px;
}

.apple-wallet-block > .error {
  color: red;
}

#google_translate_element {
  z-index: 9999;
  position: fixed;
  margin-top: 0;
  margin-left: 10px;
}

.skiptranslate iframe {
  visibility: hidden !important;
}

html body {
  top: 0 !important;
}

#noty_topRight_layout_container li {
  height: auto !important;
}

.answers-cell {
  box-shadow: 2px 1px 3px 0px lightgrey;
  min-height: 500px;
}

answer-multi-select-draggable .answers-cell {
  max-height: 500px;
}

answer-multi-select-draggable .answers-cell .answers-source {
  min-height: 500px;
}

.fictive-container {
  height: 500px;
  overflow-y: auto;
}

.answers-cell:nth-child(even) {
  box-shadow: -2px 1px 3px 0px lightgrey;
}

@keyframes wiggle {
  42%,
  58% {
    transform: translate3d(-1px, 0, 0);
  }

  44%,
  56% {
    transform: translate3d(2px, 0, -2px);
  }

  46%,
  50%,
  54% {
    transform: translate3d(-4px, -2px, 0);
  }

  48%,
  52% {
    transform: translate3d(4px, 2px, 0);
  }
}

.answers-cell.untouched:nth-child(even):after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(213, 213, 213, 0);
  border-right-color: lightgrey;
  border-width: 30px;
  margin-top: -30px;
}

.answers-cell.untouched:after {
  animation: wiggle 3s 0s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.answers-cell.untouched:hover:after {
  content: attr(hint);
  width: 100%;
  right: 100%;
  text-align: right;
  padding: 0;
  border-width: 5px;
  border-color: transparent;
  border-right-color: transparent;
  margin-top: -15px;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 4px #fff,
    0 0 7px #fff, 0 0 8px #fff, 0 0 10px #fff, 0 0 15px #fff;
}

.answers-destination {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.answers-destination .answer-container {
  opacity: 0.9;
}

.answers-destination .answer-container img {
  -webkit-filter: drop-shadow(5px 5px 5px #222222);
  filter: drop-shadow(5px 5px 5px #222222);
}

.answers-destination .answer-container {
  position: absolute;
}

.answers-source {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.answers-source .answer-container:nth-child(even) {
  align-self: flex-end;
}

.answers-source .answer-container {
  margin-bottom: 5px;
  margin-top: 5px;
}

.answers-source .answer-container .answer-value-wrapper {
  padding-left: 0;
}

.answer-container {
  display: flex;
  cursor: pointer;
}

.answer-container,
.answer-container * {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.answer-container > * {
  align-self: center;
}

.answer-container img {
  max-width: 110px;
  max-height: 110px;
}

.answer-container .answer-value {
  display: block;
  overflow: hidden;
  padding: 10px 0 10px 10px;
}

.answers-destination .answer-container .answer-value {
  text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 4px #fff,
    0 0 7px #fff, 0 0 8px #fff, 0 0 10px #fff, 0 0 15px #fff;
}

answer-multi-select-draggable .row-answers {
  max-width: 1200px;
  margin: auto;
}

button:focus {
  outline: 0 !important;
}

.upload-area {
  text-align: center;
}

.upload-area .drop-area {
  position: relative;
  min-height: 60px;
  padding: 15px;
  margin-bottom: 3px;
  border: 2px dashed #999;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 0;
}

.upload-area .drop-area:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

image-upload .drop-area {
  height: 200px;
}

image-upload .drop-area:hover {
  background: #f6f6f6;
}

.upload-area .drop-area .img-preview {
  display: inline-block;
  max-width: 95%;
  min-width: 8px;
  max-height: 100%;
  border: 1px solid #dddddd;
  vertical-align: middle;
}

.upload-area .drop-area.empty .img-preview {
  display: none;
}

.upload-area .drop-area.empty:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 18px;
  height: 18px;
  background: url('../images/upload-icon.png') 50% 50%;
}

.image-upload .drop-area img {
  height: 125px;
  width: 125px;
  margin-top: 8px;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 200px;
}

div.signature {
  width: 100%;
}

.reset-link,
.reset-link:hover {
  text-decoration: none;
}
