#keyboard {
    padding: 10px;
    margin: 25px auto;
    background: rgba(224, 231, 234, 0.9);
    border-radius: 5px;
    box-shadow: 2px 2px rgba(191, 200, 206, 0.9);
    max-width: 715px;
    width: 100%; /* IE11 fix */
    z-index: 999;
}

#keyboard, #keyboard * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#keyboard:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

#keyboardCapitalLetter {
    display: block;
}

#keyboardSmallLetter {
    display: none;
}

#keyboardNumber {
    display: none;
}

#keyboardSymbols {
    display: none;
}

.button {
    width: 50px; height: 50px;
    background-color: #fff;
    color: rgba(14, 50, 88, 0.9);
    position: relative;
    float: left;
    margin-right: 9px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 1em;
    -webkit-box-shadow: 1px 1px 1px 1px #9aafb3;
    -moz-box-shadow: 1px 1px 1px 1px #9aafb3;
    box-shadow: 1px 1px 1px 1px #9aafb3;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #ffffff; /* old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* firefox */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#eef6f7)); /* webkit */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eef6f7',GradientType=0 ); /* ie */
}

.buttonDown {
    background: none;
    background-color: #c9cdd5;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.button_a, .button_dash, .button_underscore {
    margin-left: 26px;
}

.button_enter, .button_del {
    width: 70px;
    font-size: 1em;
}

.button_del {
    font-size: 1.3em;
}

.button_smallletter, .button_capitalletterleft {
    font-size: 1em;
    width: 70px;
}

.button_capitalletterright, .button_numberright, .button_symbolsright {
    width: 129px;
    font-size: 1em;
}

.button_numberleft, .button_symbolsleft {
    width: 100px;
    font-size: 1em;
    margin-left: 26px;
}

.button_space {
    width: 355px;
}

.key {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 12px;
    text-shadow: 0px 1px 1px #ffffff;
    filter: dropshadow(color=#ffffff, offx=0, offy=1);
}

.key_del, .key_enter, .key_smallletter, .key_capitalletterleft, .key_capitalletterright, .key_number, .key_symbols {
    padding-top: 12px;
}

.key_del {
    padding-top: 9px;
}

.button:active {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(82, 168, 236, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(82,168,236,0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(82, 168, 236, 0.6);
}

#keyboardNumber .button_capitalletterleft, #keyboardNumber .empty_button,
#keyboardSymbols .button_capitalletterleft, #keyboardSymbols .empty_button,
.button_enter {
    visibility: hidden;
}

#virtualKeyboard {
    bottom: 0;
    width: 100%;
}

#virtualKeyboard {
    bottom: 0;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: end;
    display: flex;
}

#keyboard {
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-end;
}

@media (max-width: 748px) {
    .button {
        margin-right: 3px;
    }

    .button_enter, .button_del {
        width: 120px;
    }
}

@media (max-width: 732px) {
    .button_enter, .button_del {
        width: 100px;
    }

    .button_space {
        width: 340px;
    }
}

@media (max-width: 722px) {
    .button_enter, .button_del {
        width: 80px;
    }

    .button_space {
        width: 320px;
    }
}

@media (max-width: 702px) {
    .button_enter, .button_del {
        width: 70px;
    }

    .button_space {
        width: 300px;
    }
}

@media (max-width: 692px) {
    .button_enter, .button_del {
        width: 60px;
    }

    .button_space {
        width: 300px;
    }
}

@media (max-width: 682px) {
    .button {
        width: 45px; height: 45px;
    }

    .key {
        padding-top: 7px;
    }

    .key_del, .key_enter, .key_smallletter, .key_capitalletterleft, .key_capitalletterright, .key_number, .key_symbols {
        padding-top: 7px;
    }

    .key_del {
        padding-top: 4px;
    }

    .button_enter, .button_del {
        width: 80px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 50px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 90px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 90px;
    }

    .button_space {
        width: 345px;
    }
}

@media (max-width: 652px) {
    .button_enter, .button_del {
        width: 70px;
    }

    .button_space {
        width: 335px;
    }
}

@media (max-width: 632px) {
    .button {
        width: 40px; height: 40px;
    }

    .button_enter, .button_del {
        width: 70px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 70px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 70px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 70px;
    }

    .button_space {
        width: 300px;
    }
}

@media (max-width: 582px) {
    .button {
        width: 35px; height: 35px;
    }

    .key {
        padding-top: 5px;
    }

    .key_del, .key_enter, .key_smallletter, .key_capitalletterleft, .key_capitalletterright, .key_number, .key_symbols {
        padding-top: 5px;
    }

    .key_del {
        padding-top: 2px;
    }

    .button_a, .button_dash, .button_underscore {
        margin-left: 15px;
    }

    .button_enter, .button_del {
        width: 80px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 70px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 70px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 70px;
        margin-left: 15px;
    }

    .button_space {
        width: 280px;
    }
}

@media (max-width: 542px) {

    .button_enter, .button_del {
        width: 60px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 50px;
    }

}

@media (max-width: 532px) {
    .button {
        width: 30px; height: 30px;
    }

    .key {
        padding-top: 2px;
    }

    .key_del, .key_enter, .key_smallletter, .key_capitalletterleft, .key_capitalletterright, .key_number, .key_symbols {
        padding-top: 2px;
    }

    .key_del {
        padding-top: 2px;
    }

    .button_a, .button_dash, .button_underscore {
        margin-left: 26px;
    }

    .button_enter, .button_del {
        width: 80px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 50px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 50px;
        font-size: 12px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 50px;
        margin-left: 26px;
    }

    .button_space {
        width: 270px;
    }
}

@media (max-width: 492px) {

    .button_enter, .button_del {
        width: 60px;
    }

    .button_space {
        width: 260px;
    }

}

@media (max-width: 482px) {
    .button {
        width: 25px; height: 25px;
    }

    .key {
        padding-top: 2px;
    }

    .key_del, .key_enter, .key_smallletter, .key_capitalletterleft, .key_capitalletterright, .key_number, .key_symbols {
        padding-top: 2px;
    }

    .key_del {
        padding-top: 0px;
    }

    .button_a, .button_dash, .button_underscore {
        margin-left: 15px;
    }

    .button_enter, .button_del {
        width: 80px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 50px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 50px;
        font-size: 12px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 50px;
        margin-left: 15px;
    }

    .button_space {
        width: 200px;
    }
}

@media (max-width: 442px) {

    #virtualKeyboard, #keyboard {
        max-width: 335px;
    }

    .button {
        width: 20px; height: 20px;
        font-size: 12px;
    }

    .key {
        padding-top: 2px;
    }

    .key_del, .key_enter, .key_smallletter, .key_capitalletterleft, .key_capitalletterright, .key_number, .key_symbols {
        padding-top: 2px;
    }

    .key_del {
        padding-top: 2px;
    }

    .button_a, .button_dash, .button_underscore {
        margin-left: 15px;
    }

    .button_enter, .button_del {
        width: 45px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 50px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 50px;
        font-size: 12px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 50px;
        margin-left: 15px;
    }

    .button_space {
        width: 160px;
    }
}

@media (max-width: 375px) {

    #virtualKeyboard, #keyboard {
        max-width: 305px;
    }

    .button {
        width: 20px; height: 20px;
        margin-right: 2px;
    }

    .key {
        padding-top: 2px;
    }

    .key_del, .key_enter, .key_smallletter, .key_capitalletterleft, .key_capitalletterright, .key_number, .key_symbols {
        padding-top: 2px;
    }

    .key_del {
        padding-top: 1px;
    }

    .button_a, .button_dash, .button_underscore {
        margin-left: 10px;
    }

    .button_enter, .button_del {
        width: 50px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 40px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 40px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 40px;
        margin-left: 10px;
    }

    .button_space {
        width: 150px;
    }
}

@media (max-width: 360px) {

    #virtualKeyboard, #keyboard {
        max-width: 290px;
    }

    .button_enter, .button_del {
        width: 30px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 30px;
    }
}


@media (max-width: 342px) {
    .button {
        width: 13px; height: 20px;
        margin-right: 2px;
    }

    .button_del {
        width: 16px;
    }

    .button_enter {
        width: 0px;
    }

    .button_smallletter, .button_capitalletterleft {
        width: 18px;
        font-size: 10px;
        margin-right: 15px;
    }

    .button_capitalletterleft {
        font-size: 8px;
        padding-top: 3px;
    }

    .button_numberleft, .button_symbolsleft {
        width: 35px;
        margin-left: 10px;
    }

    .button_capitalletterright, .button_numberright, .button_symbolsright {
        width: 35px;
    }

    .button_space {
        width: 67px;
    }
}

@media (max-width: 258px) {
    .button_smallletter, .button_capitalletterleft {
        margin-right: 1px;
    }
}