.swap-reward-box {
    margin: 30px;
}

.swap-reward-box h3 {
    margin: 5px 0;
}

@media screen and (max-width: 768px) {
    .swap-reward-box {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.swap-reward-box .btn-row {
    text-align: center;
}

.swap-reward-box .btn-row {
    text-align: center;
}

.swap-reward-box .btn-row .btn {
    min-width: 120px;
    margin: 0 5px 15px;
}

.swap-reward-box .mobile-description {
    font-size: 10px;
}

.swap-reward-box .mobile-description.large {
    font-size: 16px;
}

.swap-reward-box .panel-body {
    padding: 0;
}

.swap-reward-modal .modal-body {
    overflow: scroll;
}

@media (min-width: 768px) {
    .swap-reward-box .panel-body {
        padding: 0 15px;
    }
}

.swap-panel {
    background: #FAFAFA;
}

.swap-panel > ul,
.current-mobile-reward > ul {
    padding-left: 0;
    list-style-type: none;
    display: block;
    border-top: 1px solid #CCC;
    background: #EEE;
    color: black;
    margin-bottom: 0;
}

.current-mobile-reward > ul {
    background: #337ab7;
    color: #FFF;
    font-weight: normal;
}

.swap-panel > ul > li, .current-mobile-reward > ul > li {
    height: 50px;
    display: table;
    margin: 0 auto;
}

.swap-panel > ul > li > div, .current-mobile-reward > ul > li > div {
    display: table-cell;
    vertical-align: middle
}

.swap-button > button {
    white-space: normal;
}

.modal-header.swap-modal {
    background: #EEE;
    border-radius: 6px 6px 0 0;
}

.modal-header.swap-modal h3 {
    margin: 0;
}

.modal-body.swap-modal img {
    margin-right: 10px;
    max-width: 100px;
}

.won-reward div.myreward {
    /*height: 350px;
    background-color: red;*/
    width: 100%;
    -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out, height 300ms ease-in-out;
    transition: width 500ms ease-in-out;
}

.won-reward div.myreward.smallermybox {
    font-size: 11px;
}

.won-reward div.myreward.smallermybox img {
    max-width: 100px;
}

.won-reward .reward-delivery {

}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@-webkit-keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 1;
        -webkit-transform: translate3d(100px, 0, 0);
        transform: translate3d(100px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 1;
        -webkit-transform: translate3d(100px, 0, 0);
        transform: translate3d(100px, 0, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
        transform: translate3d(-25px, 0, 0);
    }

    75% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
        transform: translate3d(-5px, 0, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}

.bounceInRight {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 1;
        -webkit-transform: translate3d(0, 100px, 0);
        transform: translate3d(0, 100px, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    75% {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}


