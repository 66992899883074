.delivery-reward-page .reward-delivery {
    min-height: 230px;
    position: relative;
}

.delivery-reward-page .reward-delivery .reward-info {
    /*height: 460px;*/
}

.delivery-reward-page .reward-delivery .description {
    max-height: 50%;
    min-height: 40%;
    overflow: auto;
    margin-bottom: 10px;
}

.delivery-reward-page .reward-delivery .terms {
    font-size: 8pt;
    max-height: 40%;
    overflow: auto;
}

.delivery-reward-page .reward-delivery h2 {
    /* padding-top: 0;
     padding-bottom: 0;
     margin: 0;*/
}

.delivery-reward-page .reward-delivery .delivery-message {
    display: none;
}

.mobile-reward-delivery {
    margin-bottom: 10px;
}

.mobile-reward-delivery .reward-name {
    display: block;
    margin: 0 0 2px;
}

.mobile-reward-delivery .img-reward {
    float: left;
    max-width: 60px;
    margin: 0 8px 5px 5px;
}

.mobile-reward-delivery .expiration-row {
    margin: 4px 0 0;
    font-size: 75%;
}

.mobile-reward-delivery .countdown-row {
    margin: 5px 0 0;
    text-align: center;
}

.mobile-reward-delivery ul.reward-list-view {
    padding-left: 0;
    list-style-type: none;
    display: block;
}

.mobile-reward-delivery ul.reward-list-view li {
    border-bottom: 1px solid #CCC;
}

.mobile-reward-delivery ul.reward-list-view li div.list-view-img,
.mobile-reward-delivery ul.reward-list-view li div.list-view-text
{
    float: left;
    border: 0px solid black;
    min-height: 100px;
    display: table;
    vertical-align: middle;
}

.mobile-reward-delivery ul.reward-list-view li div.list-view-img {
    width: 15%;
    display: table;
}

.mobile-reward-delivery ul.reward-list-view li div.list-view-img img {
    max-width: 40px;
     display: table-cell;
    vertical-align: middle;
}

.mobile-reward-delivery ul.reward-list-view li div.list-view-text {
    width: 85%;
    padding: 10px;
}

.mobile-reward-delivery ul.reward-list-view li div.list-view-text >div.mobile-name,
.mobile-reward-delivery ul.reward-list-view li div.list-view-text >div.mobile-description {
    font-size: 100%;
}

.mobile-reward-delivery ul.reward-list-view li div.list-view-text >div.expires {
    margin-top: 5px;
    font-size: 75%;
}

.mobile-reward-delivery ul.reward-list-view li div.list-view-img>span {
    display: table-cell;
    vertical-align: middle;
}

.delivery-reward-page .reward-delivery .logo img {
    max-height: 100px;
    width: 100px;
    position: absolute;
    bottom: 0;
    left: 30px;
}

.delivery-reward-page .reward-send-button {
    margin-top: 20px;
    margin-bottom: 10px;
}

.delivery-reward-page .print-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.delivery-reward-page .electronic-delivery {
    margin-bottom: 10px;
}

.delivery-reward-page .disclaimer {
    line-height: 1.5em;
    font-size: 85%;
    margin: 0;
    padding: 5px 10px;
    margin-top: 5px;
}

.delivery-reward-page h3.instant-reward-heading {
    margin-top: 0;
}

.delivery-reward-page .paper-reward-link,
.delivery-reward-page .electronic-reward-link {
    color: #999;
    cursor: pointer;
    margin-top: 20px;
    font-size: 85%;
}

.delivery-reward-page .paper-reward-link:hover,
.delivery-reward-page .electronic-reward-link:hover {
    text-decoration: underline;
}

.delivery-reward-page .electronic-reward-link {
    color: black;
}

.delivery-reward-page .client-logo-bottom {
    display: none;
}

.delivery-reward-page .client-logo-bottom .client-logo {
    margin: 0;
}

.delivery-reward-page .reward-row {
    margin: 30px;
}

.delivery-reward-page .terms-link {
    display: block;
    text-decoration: underline;
    cursor: pointer;
}

redeem h2 {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}

redeem .reward-row {
    margin: 30px 0 0;
}

.reward-delivery .skip-btn {
    min-width: 120px;
}

.has-error .country-codes-dropdown > .btn {
    background-color: #f2dede;
    border-color: #a94442;
}

answer-free-form-single-line .input-group {
    width: 100%;
}

.input-group .country-codes-dropdown > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.input-group .country-codes-dropdown.btn-extra-padding > .btn {
    padding: 12px 12px;
}

.input-group > * + .country-codes-dropdown > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group .country-codes-dropdown > .btn[disabled] {
    background-color: #eee;
    opacity: 1;
}

.country-codes-dropdown .dropdown-menu {
    max-height: 241px;
    overflow: auto;
}

@media (max-width: 768px) {

    .delivery-reward-page .reward-row {
        margin-left: 12px;
        margin-right: 12px;
    }

    .delivery-reward-page .reward-delivery {
        min-height: 1px;
    }

    .delivery-reward-page .panel-body {
        padding-top: 0;
        padding: 15px;
    }

    .delivery-reward-page .panel-body img {
        height: auto;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
        float: none;
        display: block;
    }

    .delivery-reward-page .btn-group .responsive {
        color: red;
    }

    .delivery-reward-page .input-group {
        width: 100%;
    }

    .delivery-reward-page .form-group-email .form-control,
    .delivery-reward-page .form-group-phone .form-control {
        border-top-right-radius: 4px!important;
        border-bottom-right-radius: 4px!important;
    }

    .country-codes-dropdown {
        position: static;
    }

    .country-codes-dropdown .dropdown-menu {
        max-width: 100%;
        max-height: 236px;
        top: auto;
        bottom: 100%;
        margin: 2px 0;
    }
}


/* Delivery Modal */

.delivery-reward-box {
    border-radius: 12px;
    font-family: Helvetica, Arial;
    text-transform: uppercase;
    font-size: 75%;
}

.delivery-reward-box .modal-content {
    border-radius: 12px;
}

.delivery-reward-box .modal-body {
    background: #F5F5F5;
    color: #333333;
    border: 3px solid #666666;
    /*padding:0;*/
}

.delivery-reward-box .reward-img {
    position: absolute;
    width: 100px;
    right: -60px;
    top: -20px;
    background: white;
    opacity: .95;
    border-radius: 100px;
}

.delivery-reward-box .img-caption {
    position: absolute;
    right: -65px;
    top: 60px;
    width: 110px;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 2px #CCC;
}

.delivery-reward-box div.user-input,
.privacy-notice {
    padding: 5px 10px;
}

.delivery-reward-box div.user-input {
    margin-top: 10px;
}

.delivery-reward-box .checkbox input[type=checkbox] {
    margin-top: 2px;
}

.delivery-reward-box div.user-input button {
    background: #000000;
    color: #FFFFFF;
    border-color: transparent;
    z-index: 2;
}

.delivery-reward-box div.icon 
{ 
    float: left;
    width: 15%;
}

.delivery-reward-box div.icon img {
    width: 40px;
    height: 40px;
}

.delivery-reward-box .title {
    font-size: 36px;
    display: block; 
    line-height: 1em;
    padding: 15px;
}      

.delivery-reward-box div.selection {
    float: left;
    text-align: center;
    width: 25%;
    margin-top: 10px;
    cursor: pointer;
}

.delivery-reward-box div.selection img {
    height: 40px;
    width: 40px;
}

@media (max-width: 430px) {
  .delivery-reward-box .title {
    font-size: 24px;
    line-height: 1em;
  }
  .delivery-reward-box div.icon {padding-top: 5px;}
  .delivery-reward-box div.icon img {width: 30px; height: 30px;}
}



/* New Delivery Modal */

delivery-reward-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
}

delivery-reward-modal .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: .5;

}

.modal.modal-delivery-reward {
    position: absolute;
    display: block;
    opacity: 1;
    -webkit-transition: none;
    transition: none;
    z-index: 1050;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.modal.modal-delivery-reward .modal-dialog {
    width: auto;
    margin: 10px;

    -webkit-transition: none;
    transition: none;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

@media (min-width: 788px) {
    .modal.modal-delivery-reward .modal-dialog {
        width: 768px;
        margin: 30px auto;
    }
}

.modal.modal-delivery-reward .modal-body {
    padding: 0;
}

.modal.modal-delivery-reward .modal-content {
    border: none;
    border-radius: 0;
}

.delivery-options {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.delivery-options:before,
.delivery-options:after {
    content: "";
    display: table;
    clear: both;
}

.delivery-options .bottom-row-wrapper {
	min-height: 60px;
}

.delivery-options .title {
    margin: 22px 15px;
    font-size: 42px;
    line-height: 1.25em;
    color: #ffffff;
    text-align: center;
}

.delivery-options .extra-info {
    margin: 15px 0;
    padding: 18px 15px;
    font-size: 20px;
    line-height: 1.4em;
    color: #000;
    background: rgba(255,255,255,0.8);
    text-align: center;
}

.delivery-options .extra-info p:last-child {
    margin-bottom: 0;
}

.delivery-options .main {
    margin: 15px 0;
    color: #000000;
	padding: 20px 10px;
	background: rgba(255,255,255,0.4);
}

.delivery-options .reward-description {
	margin: 25px 0 0;
}

.delivery-options .reward {
    display: none;
	position: relative;
    margin: 0 0 10px;
    text-align: center;
}

.delivery-options .show-reward .reward {
	display: block;
}

.delivery-options .reward-inner {
    display: inline-block;
    max-width: 100%;
    border: 1px solid transparent;
    background: #ffffff;
}

.delivery-options .reward .reward-img {
    display: block;
    max-width: 100%;
    max-height: 200px;
    margin: 0 auto;
}

.delivery-options .reward .reward-name {
    margin: 12px 3px;
    font-size: 18px;
    line-height: 22px;
}

.delivery-options .options {
    margin: 0;
}

.delivery-options .option {
    margin-top: 26px;
    outline: none;
}

.delivery-options .option-disabled {
	position: relative;
	border-color: transparent;
	background: #E6E6E6;
	color: #E6E6E6;
}

.delivery-options .option-disabled:after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	cursor: default;
}

.delivery-options .option:first-child {
    margin-top: 0;
}

.delivery-options .option .option-inner {
    position: relative;
    display: block;
    width: 100%;
}

.delivery-options .option .icon-block {
    display: none;
	float: left;
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

.delivery-options .option-disabled .icon-block {
	opacity: 0.3;
}

@media (min-width: 375px) {
	.delivery-options .option .icon-block {
		display: block;
	}
}

.delivery-options .option .icon-block .icon-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.delivery-options .option .option-control-block {
    display: block;
}

.delivery-options .option .form-control,
.delivery-options .option .btn,
.delivery-options .option .dropdown-menu,
.delivery-options .option select.form-control.empty option {
    border-radius: 0;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.delivery-options .option .form-control {
    height: 40px;
	padding: 6px 8px;
	border-color: transparent;
	box-shadow: none;
}

.delivery-options .options .has-error .form-control,
.delivery-options .options .has-error .country-codes-dropdown > .btn {
	border-color: #a94442;
	background: rgb(247, 227, 227);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.delivery-options .option .btn {
    padding-top: 8px;
    padding-bottom: 8px;
	background: #ffffff;
	border-color: transparent;
    z-index: 2;
}

.delivery-options .option-disabled .btn {
	color: #E6E6E6;
}

.delivery-options .option .btn-submit {
    min-width: 80px;
    background: #404040;
    color: #ffffff;
    transition: background 0.2s ease-out;
}

.delivery-options .option-disabled .btn-submit {
	background: #BCBCBC;
	color: #E6E6E6;
}

@media (min-width: 370px) {
	.delivery-options .option .btn-submit {
		min-width: 100px;
	}
}

@media (min-width: 480px) {
	.delivery-options .option .btn-submit {
		min-width: 128px;
	}
}

.delivery-options .option .input-group-btn {
    text-align: right;
}

.delivery-options answers {
	margin: 0;
}

.delivery-options .answer-address-box ::-webkit-input-placeholder {
	font-size: 13px;
    color: #808080;
}

.delivery-options .answer-address-box ::-moz-placeholder {
	font-size: 13px;
    color: #808080;
}

.delivery-options .answer-address-box :-ms-input-placeholder {
	font-size: 13px;
    color: #808080;
}

.delivery-options .option select.empty {
    font-size: 13px;
    color: #808080;
}

.delivery-options select option.placeholder {
    display: none;
}

.delivery-options .row {
	margin-left: -4px;
	margin-right: -4px;
}

.delivery-options *[class^="col-md-"],
.delivery-options *[class^="col-sm-"],
.delivery-options *[class^="col-xs-"] {
	padding-left: 4px;
	padding-right: 4px;
}

.delivery-options .option-mail-address .icon-block {
	display: none;
}

@media (min-width: 500px) {
	.delivery-options .option-mail-address .icon-block {
		display: block;
	}

	.delivery-options .answer-address-box > div:first-child {
		width: calc(50% - 48px);
		margin-left: -4px;
	}

	.delivery-options .answer-address-box *[class^="col-sm-"] {
		float: left;
	}

	.delivery-options .answer-address-box .col-sm-12 {
		width: 100%;
	}

	.delivery-options .answer-address-box .col-sm-11 {
		width: 91.66666667%;
	}

	.delivery-options .answer-address-box .col-sm-10 {
		width: 83.33333333%;
	}

	.delivery-options .answer-address-box .col-sm-9 {
		width: 75%;
	}

	.delivery-options .answer-address-box .col-sm-8 {
		width: 66.66666667%;
	}

	.delivery-options .answer-address-box .col-sm-7 {
		width: 58.33333333%;
	}

	.delivery-options .answer-address-box .col-sm-6 {
		width: 50%;
	}

	.delivery-options .answer-address-box .col-sm-5 {
		width: 41.66666667%;
	}

	.delivery-options .answer-address-box .col-sm-4 {
		width: 33.33333333%;
	}

	.delivery-options .answer-address-box .col-sm-3 {
		width: 25%;
	}

	.delivery-options .answer-address-box .col-sm-2 {
		width: 16.66666667%;
	}

	.delivery-options .answer-address-box .col-sm-1 {
		width: 8.33333333%;
	}
}

.delivery-options .form-group {
	margin: 0 0 8px;
}

.delivery-options .option > .checkbox {
	margin: 20px 0 0;
}

@media (min-width: 375px) {
	.delivery-options .option > .checkbox {
		margin-left: 14px;
	}
}

@media (min-width: 720px) {
    .delivery-options .title {
        margin-left: auto;
        margin-right: auto;
        max-width: 470px;
    }

	.delivery-options .main {
		width: 672px;
		margin-left: auto;
		margin-right: auto;
		padding: 30px;
		border-radius: 30px;
	}

    .delivery-options .show-reward .main-inner {
        width: 100%;

	    display: -ms-flexbox;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;

	    -ms-flex-direction: row;
	    flex-direction: row;
    }

    .delivery-options .show-reward .reward {
        margin: 0;
        /*width: 190px;*/

	    -webkit-flex: 1 1 150px;
	    -ms-flex: 1 1 150px;
	    flex: 1 1 150px;

        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
	    justify-content: center;
    }

    .delivery-options .show-reward .reward .reward-img {
        max-width: 148px;
        max-height: 148px;
    }

    .delivery-options .show-reward .options {
	    -webkit-flex: 1 1 440px;
	    -ms-flex: 1 1 440px;
	    flex: 1 1 440px;

	    display: -ms-flexbox;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;

        margin: 0 0 0 22px;
    }

	.delivery-options .show-reward .options {
		-webkit-flex: 1 1 440px;
		-ms-flex: 1 1 440px;
		flex: 1 1 440px;

		display: -ms-flexbox;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;

		-ms-flex-align: center;
		-webkit-align-items: center;
		-webkit-box-align: center;

		align-items: center;

		margin: 0 0 0 22px;
	}

    .delivery-options .show-reward .options-inner {
        width: 100%;
    }
}