.screensaver {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #EEE;
    z-index: 9999;
}

.screensaver .carousel {
    position: absolute;
    width: 100%;
    height: 100%;
}

.screensaver .carousel-control,
.screensaver .carousel-indicators {
    display: none;
}

.screensaver .carousel-inner,
.screensaver .carousel .item {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    /*font-size: 0;*/
}

.screensaver .carousel .slide-img,
.screensaver .carousel video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.screensaver .carousel .video-wrapper {
    position: static;
}

.screensaver .carousel .alert {
    position: relative;
    z-index: 1;
}