.pin-page {
    background-image: url('/images/dock-light.jpg');
    background-attachment: fixed !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: auto !important;
    min-height: 100%;
    height: 100%;
    padding-bottom: 90px;
    width: 100%;
}

.container-fluid {
    padding: 0;
}


.pin-page .nav {
    margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
    .pin-page .panel-heading .client-logo-img {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.keypad-box .alert {
    display: inline-block;
}

keypad {
    display: block;
}

.keypad {
    display: inline-block;
    margin: 10px 0;
    border: 8px solid #CCC;
    background: #DDD;
    padding: 8px;
}

.keypad:after {
    content: "";
    display: table;
    clear: both;
}

.keypad .numbers {

}

.keypad .actions {
    margin: 0 5px;
}

@media screen and (min-width: 430px) {
    .keypad .numbers,
    .keypad .actions {
        float: left;
    }
}

.keypad .button-row {
    white-space: nowrap;
}

.keypad .button-row .btn-lg {
    height: 60px;
    width: 60px;
    margin: 5px;
}

.keypad .button-row .btn-lg.action-btn {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;

    height: 46px;
}

.keypad .button-row .action-btn.btn-mock {
    display: none;
}

@media screen and (min-width: 430px) {
    .keypad .button-row .action-btn.btn-mock {
        display: block;
    }
}

.keypad-box .keypad .button-row {
    text-align: center;
}

.keypad-box .keypad .button-row button.placeholder {
    visibility: hidden;
}

.keypad-box .keypad .button-row .btn-disabled{
    opacity: .25;
}

.keypad-box .pin-code {
    font-size: 40pt;
    line-height: 50px;
    height: 50px;
    padding-top: 10px;
}

.keypad-box .btn .reward-code-value {
    display: block;
}

.keypad-box .pin-code-message {
    font-size: 16pt;
    height: 50px;
    padding-top: 10px;
}

.keypad-box .messages {
    min-height: 50px;
    /*margin: 10px 0 0;*/
    text-align: center;
}

.keypad-box .alert-pin{
    margin: 0 auto;
    padding: 10px;
}

.keypad-box .btn-special-character {
    font-size: 1em;
}

.drawing-btn {
    background-color: #774381;
    color: white;
}